import React, {
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
	useRef,
} from "react";
import {
	FETCH_UTM_URL,
	SAVE_UTM_URL,
	TOGGLE_UTM_OVERRIDE_URL,
	TOGGLE_UTM_URL,
} from "../../conf";
import useResource from "../../hooks/useResource";
import {
	PARAM_TYPES,
	makeValidParam,
	validateParam,
} from "../../utils/paramUtils";
import parseError from "../../utils/parseError";
import uuid4 from "../../utils/uuid4";
import { StatusToggle } from "../CoreUI";
import {
	Button,
	Loader,
	PaddedContainer,
	Text,
	StatusLabel,
	SPACING,
	STATUS,
	FONT_COLOR,
	FONT_SIZE,
} from "@disco/disco_core";
import UTMParamGroup from "../UTMParamGroup";
import FloatingModal from "./FloatingModal";
import "./utm-modal.css";
import windowRedirect from "../../utils/windowRedirect";
import { UTM_INSTRUCTIONS_URL } from "../../conf";
import Confirmation from "../modals/Confirmation";
import useModal from "../../hooks/useModal";

const UTMModal = memo(({ user, setUser, onClose, open, ...rest }) => {
	const [params, setParams] = useState(null);
	const originalParams = useRef(null);
	const [payload, setPayload] = useState();
	const [formError, setFormError] = useState("");

	const [{ loading, data, error }, load, reset] = useResource(
		{
			url: FETCH_UTM_URL,
			method: "GET",
		},
		false
	);

	const [
		{ loading: saveLoading, data: saveData, error: saveError },
		save,
		saveReset,
	] = useResource(
		{
			url: SAVE_UTM_URL,
			method: "POST",
			data: payload,
		},
		false
	);

	useEffect(() => {
		if (!params && open) load();
	}, [open, load, params]);

	useEffect(() => {
		if (!data) {
			return;
		}

		const dataParams = data.map((param) => ({ ...param, id: uuid4() }));

		setParams(dataParams);
		originalParams.current = dataParams;

		reset();
	}, [data, reset]);

	useEffect(() => {
		if (!saveData) {
			return;
		}
		saveReset();

		onClose();
		setParams(null);
	}, [saveData, saveReset, onClose, params]);

	const handleCancel = useCallback(() => {
		onClose();
		setParams(null);
	}, [onClose]);

	const handleConfirmationCancel = useCallback(() => {
		handleCancel();
	}, [handleCancel]);

	const handleSave = useCallback(() => {
		for (const param of params) {
			const error = validateParam(param);
			if (error) {
				setFormError(error);
				return;
			}
		}
		setFormError("");
		setPayload(params.map(makeValidParam));
		save();
	}, [params, save]);

	const {
		open: closeConfirmationOpen,
		handleClose: handleCloseConfirmationClose,
		handleOpen: handleCloseConfirmationOpen,
	} = useModal();

	const handleConfirmation = useCallback(() => {
		handleSave();
		handleCloseConfirmationClose();
	}, [handleSave, handleCloseConfirmationClose]);

	const handleClose = useCallback(() => {
		if (JSON.stringify(params) !== JSON.stringify(originalParams.current)) {
			handleCloseConfirmationOpen();
			return;
		}

		handleCancel();
	}, [handleCloseConfirmationOpen, params, originalParams, handleCancel]);

	const paramGroups = useMemo(() => {
		const premium = [],
			upsell = [];
		if (!params) return null;
		params.forEach((param) => {
			if (param.surface_type === PARAM_TYPES.PREMIUM) premium.push(param);
			else if (param.surface_type === PARAM_TYPES.UPSELL)
				upsell.push(param);
		});

		const res = [
			{
				params: premium,
				surface: PARAM_TYPES.PREMIUM,
				name: "Cross-sell UTM",
			},
			{
				params: upsell,
				surface: PARAM_TYPES.UPSELL,
				name: "Upsell UTM",
			},
		];

		return res;
	}, [params]);

	const handleInstructionsClick = () => {
		windowRedirect(UTM_INSTRUCTIONS_URL);
	};

	return (
		<>
			<Confirmation
				open={closeConfirmationOpen}
				onClose={handleCloseConfirmationClose}
				onCancel={handleConfirmationCancel}
				onConfirmation={handleConfirmation}
				title={"Save changes before leaving"}
				cancelButtonText="Leave Without Saving"
				confirmationButtonText="Save Changes"
			>
				<Text
					size={FONT_SIZE.SUB_TITLE}
					color={FONT_COLOR.MID}
					marginTop={SPACING.SMALL}
					marginBottom={SPACING.REGULAR}
				>
					All unsaved changes will be lost. Are you sure you want to
					leave this page?
				</Text>
			</Confirmation>
			<FloatingModal
				{...rest}
				className="utm-modal"
				heading="UTM Settings"
				fixedHeader
				designSystem
				onClose={handleClose}
				open={open}
				closer={
					<PaddedContainer hPadding={SPACING.REGULAR} flexContent>
						<PaddedContainer flexContent vPadding={SPACING.TINY}>
							{saveLoading ? (
								<Loader
									small2
									className="variants-modal-save"
								/>
							) : (
								<>
									<Button
										onClick={handleCancel}
										hPadding={SPACING.REGULAR}
										vPadding={SPACING.TINY}
										marginRight={SPACING.TINY}
										secondary
									>
										Cancel
									</Button>

									<Button
										disabled={saveLoading}
										onClick={handleSave}
										hPadding={SPACING.REGULAR}
										vPadding
									>
										Save
									</Button>
								</>
							)}
						</PaddedContainer>
					</PaddedContainer>
				}
			>
				<PaddedContainer
					marginRight={SPACING.LARGE}
					marginLeft={SPACING.LARGE}
					marginBottom={SPACING.MEDIUM}
					marginTop={SPACING.MEDIUM}
				>
					<PaddedContainer>
						{loading && <Loader />}
						{(error || saveError || formError) && (
							<StatusLabel type={STATUS.ERROR}>
								{formError
									? formError
									: error
									? parseError(error)
									: parseError(saveError)}
							</StatusLabel>
						)}
						{params && (
							<>
								<PaddedContainer
									className="utm-params-toggle"
									marginBottom={SPACING.REGULAR}
								>
									<StatusToggle
										remoteKey="utm_params"
										user={user}
										setUser={setUser}
										url={TOGGLE_UTM_URL}
										heading="Enable UTM Parameters "
										text="This adds UTM parameters to all Disco links without replacing any existing UTM parameters defined on your product URL"
									/>
								</PaddedContainer>
								<PaddedContainer className="utm-params-toggle">
									<StatusToggle
										remoteKey="override_params"
										user={user}
										setUser={setUser}
										url={TOGGLE_UTM_OVERRIDE_URL}
										heading="Overwrite existing UTM Parameters "
										text="This will replace the UTM parameters on all links you've provided to Disco with the values below"
									/>
								</PaddedContainer>
								<PaddedContainer
									flexContent
									marginTop={SPACING.MEDIUM}
									marginBottom={SPACING.MEDIUM}
								>
									<Text color={FONT_COLOR.MID} flexContent>
										If you're configuring for Northbeam,
										Triple Whale, Rockerbox, etc.,{" "}
										<Text
											clickable
											onClick={handleInstructionsClick}
											color={FONT_COLOR.MID}
											hPadding={SPACING.MICRO}
											className="utm-modal-link"
										>
											find instructions here
										</Text>
										on setting up the integration.
									</Text>
								</PaddedContainer>

								<PaddedContainer marginBottom={SPACING.LARGE}>
									{Array.isArray(paramGroups) &&
										paramGroups.map((group) => (
											<UTMParamGroup
												{...group}
												setParams={setParams}
											/>
										))}
								</PaddedContainer>
							</>
						)}
					</PaddedContainer>
				</PaddedContainer>
			</FloatingModal>
		</>
	);
});

export default UTMModal;
