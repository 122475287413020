import React, { memo, useEffect, useCallback } from "react";
import { initInstance } from "../utils/segment";
import { SETUP_STATUS_VERBOSE } from "../conf";
import uuid4 from "../utils/uuid4";
import { GETAll } from "../utils/GET";
import useDimensions from "../hooks/useDimensions";
export const trackContext = React.createContext();

const Tracker = memo(({ user, children }) => {
	const { isMobile } = useDimensions();
	const processEvent = useCallback(
		(name, properties = {}) => {
			const allProperties = user
				? {
						setup_status:
							user.manual_setup_status ||
							SETUP_STATUS_VERBOSE[user.setup_status - 1],
						coop_user_id: uuid4(),
						isSuper: user.isSuper,
						coop_event_id: uuid4(),
						isMobile,
						...(GETAll() || {}),
						...properties,
				  }
				: GETAll();
			if (
				!window?.mixpanel?.track ||
				typeof window?.mixpanel?.track !== "function"
			)
				return;
			console.log("[ TRACK ]", name, allProperties);
			window.mixpanel.track(name, allProperties);
			return true;
		},
		[user, isMobile]
	);

	useEffect(() => {
		if (!user) {
			return;
		}
		initInstance(user);
	}, [user]);

	return (
		<trackContext.Provider value={processEvent}>
			{children}
		</trackContext.Provider>
	);
});

export default Tracker;
