import { anim, PaddedContainer } from "@disco/disco_core";
import React, { memo } from "react";
import AudiencesBanner from "../AudiencesBanner";
import { AudiencesHistoryOverview } from "../audiencesHistory";
import AudiencesIntegrations from "../audiencesIntegrations/AudiencesIntegrations";
import "./audiences-overview.css";

const AudiencesOverview = memo(({ changeTab }) => {
	return (
		<PaddedContainer
			motionElement
			Element="main"
			className="audiences-overview"
			variants={anim.simplePageContent}
			initial="initial"
			animate="animate"
			exit="exit"
		>
			<AudiencesBanner changeTab={changeTab} />
			<AudiencesIntegrations />
			<AudiencesHistoryOverview />
		</PaddedContainer>
	);
});

export default AudiencesOverview;
