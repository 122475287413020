import React, {
	useCallback,
	useEffect,
	useState,
	useMemo,
	memo,
	forwardRef,
} from "react";
import { NewReviewTooltip, TagButton } from "../CoreUI";
import "./new-edit-view.css";
import parseError from "../../utils/parseError";
import useResource from "../../hooks/useResource";
import useTrack from "../../hooks/useTrack";

import {
	genClassName,
	INPUT_TYPE,
	PaddedContainer,
	CrossIcon,
	CheckIcon,
	LabeledInput,
	StatusLabel,
	Loader,
	Text,
	FONT_SIZE,
	SPACING,
} from "@disco/disco_core";

const EditView = memo(
	forwardRef(
		(
			{
				className = "",
				onEdit,
				name,
				draftTooltipName = "",
				user,
				setUser,
				userLevel = false,
				empty = false,
				validator,
				remoteKey,
				url,
				editable = true,
				draftable = false,
				tooltipTitle = "",
				tooltipText = "",
				onFocus,
				onMouseEnter,
				onMouseLeave,
				onDiscard,
				toggleEvent,
				toggleDirect = false,
				brandColor = false,

				explicitToggleValue,
				triggerExplicitToggle,
				setTriggerExplicitToggle,

				inputType = INPUT_TYPE.TEXT,

				children,
				...props
			},
			ref
		) => {
			const isToggleInput = useMemo(
				() => inputType === INPUT_TYPE.TOGGLE,
				[inputType]
			);
			const isTextInput = useMemo(
				() => inputType === INPUT_TYPE.TEXT,
				[inputType]
			);

			const [payload, setPayload] = useState({
				url,
				method: "PUT",
				data: {},
			});
			const [{ loading, data, error }, save, reset] = useResource(
				payload,
				false
			);
			const track = useTrack();

			const currentValue = useMemo(() => {
				const currentValue = userLevel
					? user[remoteKey]
					: draftable &&
					  (user.draft[remoteKey] ||
							(empty && user.draft[remoteKey] === ""))
					? user.draft[remoteKey]
					: user.publisher[remoteKey] || "";
				return currentValue || currentValue === 0
					? currentValue.toString()
					: currentValue;
			}, [user, draftable, remoteKey, userLevel, empty]);

			const liveValue = useMemo(() => {
				const _value = userLevel
					? user[remoteKey]
					: user.publisher[remoteKey];
				return _value;
			}, [user, userLevel, remoteKey]);

			const [edited, setEdited] = useState(false);
			const [formError, setFormError] = useState(false);
			const [value, setValue] = useState(currentValue);

			const toggleState = useMemo(() => {
				if (!isToggleInput || userLevel) {
					return null;
				}
				return user.publisher[remoteKey];
			}, [isToggleInput, userLevel, remoteKey, user.publisher]);

			const handleChange = useCallback(({ target: { value } }) => {
				setFormError("");
				setValue(value);
			}, []);

			const handleToggle = useCallback(
				(toggleVal) => {
					console.log({ toggleVal });
					if (!!toggleEvent) {
						track(toggleEvent, {
							enabled: toggleVal,
						});
					}
					setPayload((payload) => ({
						...payload,
						data: {
							[remoteKey]: toggleVal,
						},
					}));
					save();
				},
				[remoteKey, save, toggleEvent, track]
			);

			useEffect(() => {
				if (
					explicitToggleValue === undefined ||
					!triggerExplicitToggle ||
					typeof setTriggerExplicitToggle !== "function"
				) {
					return;
				}
				if (triggerExplicitToggle) {
					handleToggle(explicitToggleValue);
					setTriggerExplicitToggle(false);
				}
			}, [
				triggerExplicitToggle,
				setTriggerExplicitToggle,
				handleToggle,
				explicitToggleValue,
			]);

			const handleUpdate = useCallback(
				(data) => {
					// TODO: TOGGLE => NOT DIRECT => USE TERNARY INSTEAD OF OR OPERATOR => LEADS TO UNDEFINED
					const newValue = isToggleInput
						? toggleDirect
							? data[remoteKey]
							: remoteKey in data
							? data[remoteKey]
							: remoteKey in data?.brand
							? data?.brand?.[remoteKey]
							: data?.user?.[remoteKey]
						: data?.[remoteKey] ||
						  (empty && data?.[remoteKey] === "")
						? data?.[remoteKey]
						: data?.brand?.[remoteKey]
						? data?.brand?.[remoteKey]
						: data?.user?.[remoteKey];

					setUser((user) => {
						const newUser = { ...user };
						if (userLevel) {
							return {
								...newUser,
								[remoteKey]: newValue,
							};
						}
						if (draftable) {
							newUser.draft = {
								...newUser.draft,
								[remoteKey]: newValue,
							};
						} else {
							newUser.publisher = {
								...newUser.publisher,
								[remoteKey]: newValue,
							};
						}
						return newUser;
					});
					if (typeof onEdit === "function") {
						onEdit(newValue, data);
					}
					reset();
				},
				[
					draftable,
					userLevel,
					onEdit,
					remoteKey,
					setUser,
					reset,
					empty,
					isToggleInput,
					toggleDirect,
				]
			);

			const handleDiscard = useCallback(() => {
				setValue(currentValue || "");
				setFormError("");
				reset();
				if (typeof onDiscard === "function") {
					onDiscard();
				}
			}, [reset, currentValue, onDiscard]);

			const handleClick = useCallback(() => {
				if (!value.trim().length && !empty) {
					return setFormError("Please enter the " + name);
				}
				const valid =
					typeof validator !== "function" ? null : validator(value);

				if (valid && !valid[0]) {
					return setFormError(valid[1]);
				}

				setPayload((payload) => ({
					...payload,
					data: { [remoteKey]: valid ? valid[1] : value },
				}));
				save();
				if (valid) {
					setValue(valid[1]);
				}
			}, [save, value, name, remoteKey, empty, validator]);

			useEffect(() => {
				if (!isTextInput) {
					return;
				}
				setEdited(
					value && currentValue
						? value.trim() !== currentValue.trim()
						: currentValue || value
				);
			}, [value, user.publisher, remoteKey, currentValue, isTextInput]);

			useEffect(() => {
				if (!data) {
					return;
				}
				handleUpdate(data);
			}, [
				data,
				setUser,
				onEdit,
				remoteKey,
				reset,
				draftable,
				handleUpdate,
			]);

			const handleFocus = useCallback(() => {
				if (typeof onFocus === "function") {
					onFocus(remoteKey);
				}
			}, [onFocus, remoteKey]);

			const handleMouseEnter = useCallback(() => {
				if (typeof onMouseEnter === "function") {
					onMouseEnter(remoteKey);
				}
			}, [onMouseEnter, remoteKey]);

			const inDraft = useMemo(() => {
				return (
					draftable &&
					((liveValue &&
						(currentValue || (empty && currentValue === ""))) ||
						(!liveValue && currentValue)) &&
					liveValue !== currentValue
				);
			}, [draftable, liveValue, currentValue, empty]);

			const tooltip = useMemo(
				() =>
					(tooltipTitle || tooltipText) && !inDraft
						? {
								persistent: true,
								preventOverflowRight: true,
								children: (
									<>
										{tooltipTitle && (
											<Text
												size={FONT_SIZE.LG_BODY}
												thick
											>
												{tooltipTitle}
											</Text>
										)}
										{tooltipText && (
											<Text
												size={FONT_SIZE.BODY}
												marginTop={SPACING.MICRO}
											>
												{tooltipText}
											</Text>
										)}
									</>
								),
						  }
						: null,
				[tooltipText, tooltipTitle, inDraft]
			);

			const reviewTooltip = useMemo(
				() =>
					inDraft ? (
						<NewReviewTooltip
							preventOverflowRight
							persistent
							activatorProps={{ marginLeft: SPACING.TINY }}
						/>
					) : null,
				[inDraft]
			);

			const labelProps = useMemo(
				() => ({ inReview: inDraft, children: reviewTooltip }),
				[inDraft, reviewTooltip]
			);

			return (
				<PaddedContainer
					className={genClassName({
						base: "new-edit-view",
						additional: className,
					})}
					onClick={handleFocus}
					tabIndex={1}
					onFocus={handleFocus}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={onMouseLeave}
					ref={ref}
				>
					<LabeledInput
						inputType={inputType}
						value={value || ""}
						onChange={handleChange}
						disabled={loading || !editable}
						label={name}
						labelProps={labelProps}
						tooltip={tooltip}
						name={name}
						empty={empty}
						inDraft={inDraft}
						loading={loading}
						toggle={toggleState}
						handleToggle={handleToggle}
						// onSave={handleUpdate}
						// remoteKey={remoteKey}
						{...props}
					>
						{children && children(value, setValue)}
					</LabeledInput>
					{edited && (
						<PaddedContainer
							className={genClassName({
								base: "new-edit-view-action disco-flex",
							})}
						>
							{loading && <Loader small />}
							<TagButton
								disabled={
									loading ||
									(value.trim().length === 0 && !empty)
								}
								onClick={handleClick}
								icon={<CheckIcon size={14} />}
								green
							/>
							<TagButton
								disabled={loading}
								onClick={handleDiscard}
								icon={<CrossIcon size={14} />}
								className={genClassName({
									base: "new-edit-view-discard",
								})}
								red
							/>
						</PaddedContainer>
					)}
					{(formError || error) && (
						<PaddedContainer className="new-edit-view-status disco-flex-center">
							<StatusLabel showIcon={false}>
								{formError || parseError(error)}
							</StatusLabel>
						</PaddedContainer>
					)}
				</PaddedContainer>
			);
		}
	)
);

export default EditView;
