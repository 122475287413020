import React, { memo } from "react";
import { AuthPage } from "../../components/CoreUI";
import AudiencesModule from "../../components/feedModules/AudiencesModule";
import { PLATFORM_VIEW_AUDIENCES } from "../../events";
import "./audiences-page.css";

const AudiencesPage = memo(() => {
	return (
		<AuthPage
			active="audiences"
			className="audiences-page"
			viewEvent={PLATFORM_VIEW_AUDIENCES}
		>
			<AudiencesModule />
		</AuthPage>
	);
});

export default AudiencesPage;
