import React, { memo, useCallback, useMemo } from "react";
import LookerDashPerformance from "../../components/PerformanceModules/LookerDashPerformance";
import SurveyPerformanceModule from "../../components/PerformanceModules/SurveyPerformanceModule";
import getPlatformPath from "../../utils/getPlatformPath";
import hasMigratedToExtensions from "../../utils/hasMigratedToExtensions";
import TopLevelPage, { TopLevelPageHeader } from "../TopLevelPage";
import "./performance-page.css";
import { useNavigate, useParams } from "react-router-dom";
import getPerformanceSubheadings, {
	isPerformanceInMaintenance,
} from "../../utils/getPerformanceSubheadings";
import useIdentity from "../../hooks/useIdentity";
import {
	Button,
	Card,
	FONT_SIZE,
	PaddedContainer,
	SPACING,
	Text,
} from "@disco/disco_core";
import { AuthPage, PageHeader } from "../../components/CoreUI";

const subPages = {
	summary: LookerDashPerformance,
	network: LookerDashPerformance,
	surveyPerformance: SurveyPerformanceModule,
	emailPerformance: LookerDashPerformance,
	crossSell: LookerDashPerformance,
};

const PerformancePageActionButtons = memo(
	({ urlParam, btnText, btnRedirect }) => {
		const { user } = useIdentity();

		const shouldShowExtensions =
			urlParam === "summary" &&
			hasMigratedToExtensions({ user, considerOverride: true });
		const navigate = useNavigate();

		const handleRedirect = useCallback(
			(overridePath = null) => {
				navigate(overridePath || btnRedirect);
			},
			[navigate, btnRedirect]
		);

		const handlePrimaryBtn = useCallback(() => {
			if (shouldShowExtensions) {
				navigate(btnRedirect);
				return;
			}
			handleRedirect();
		}, [navigate, handleRedirect, shouldShowExtensions, btnRedirect]);

		const handleSecondaryBtn = useCallback(() => {
			handleRedirect(getPlatformPath("products", user));
		}, [user, handleRedirect]);

		if (!btnText || !btnRedirect) return null;

		return (
			<>
				{shouldShowExtensions && (
					<Button
						small
						secondary
						marginRight={SPACING.SMALL}
						onClick={handleSecondaryBtn}
					>
						{btnText}
					</Button>
				)}
				<Button small onClick={handlePrimaryBtn}>
					{shouldShowExtensions
						? "Setup Shopify Checkout Extensions"
						: btnText}
				</Button>
			</>
		);
	}
);

const PerformancePageMaintenance = () => {
	const { user } = useIdentity();
	const navigate = useNavigate();
	const handleClick = () => {
		navigate(getPlatformPath("discoFeed", user));
	};
	return (
		<AuthPage
			active="performance"
			className="performance-page performance-page-maintenance"
		>
			<PageHeader heading="Performance" />
			<Card
				vPadding={SPACING.REGULAR}
				hPadding={SPACING.LARGE}
				className="performance-page-maintenance-content"
				marginTop={SPACING.REGULAR}
			>
				<PaddedContainer>
					<iframe src="https://lottie.host/embed/e82436f5-8035-4f03-9f35-1b1ed3371d6f/hrdqyxg9Su.json"></iframe>
					<Text
						size={FONT_SIZE.HEADER}
						marginTop={SPACING.LARGE}
						center
					>
						Scheduled maintenance
					</Text>
					<Text
						size={FONT_SIZE.SUB_TITLE}
						marginTop={SPACING.REGULAR}
						center
					>
						We are working on your dashboards
					</Text>
					<Button
						onClick={handleClick}
						hMarginAuto
						marginTop={SPACING.LARGE}
					>
						Customize my DiscoFeed
					</Button>
				</PaddedContainer>
			</Card>
		</AuthPage>
	);
};

const PerformancePage = memo(() => {
	const { user } = useIdentity();
	const pageProps = useCallback(
		(subPage) => {
			const props = {
				summary: {
					btnRedirect: getPlatformPath("discoFeed", user),
					btnText: "Customize my DiscoFeed",
				},
				network: {
					type: "network",
				},
				surveyPerformance: {},
				emailPerformance: {
					btnRedirect: getPlatformPath("emails", user),
					btnText: "Customize my email settings",
					type: "email",
				},
				offerPerformance: {},
				crossSell: {
					type: "bod",
				},
			};
			return props[subPage];
		},
		[user]
	);

	const { subPage: urlParam } = useParams();

	const headerProps = useMemo(
		() => ({
			tabOptions: getPerformanceSubheadings({
				user,
				activeIdx: urlParam,
				sidebar: false,
			}),
			heading: "Performance",
		}),
		[urlParam, user]
	);

	const renderPageHeader = useMemo(() => {
		return (
			<TopLevelPageHeader
				{...headerProps}
				actionChildren={
					<PerformancePageActionButtons
						urlParam={urlParam}
						btnText={pageProps(urlParam)?.btnText}
						btnRedirect={pageProps(urlParam)?.btnRedirect}
					/>
				}
			/>
		);
	}, [headerProps, urlParam, pageProps]);

	if (isPerformanceInMaintenance({ user })) {
		return <PerformancePageMaintenance />;
	}

	return (
		<TopLevelPage
			subPages={subPages}
			pageHeader={renderPageHeader}
			pageProps={pageProps}
			className="performance-page"
		></TopLevelPage>
	);
});

export default PerformancePage;
