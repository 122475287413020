import React, {
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import BrandOptOutModalSelector from "../BrandOptOutModalSelector";
import EditView from "../EditView";
import ProductsContainer from "../ProductsContainer";
import SubCategoryOptOutModalSelector from "../SubCategoryOptOutModalSelector";
import WidgetModule, { WidgetModuleToggler } from "./WidgetModule";

import {
	PREVIEW_TYPES_IDX,
	SAVE_BRAND_URL,
	SAVE_HEADER_URL,
	SAVE_NETWORK_URL,
	SAVE_PUBLISHER_URL,
} from "../../conf";

import {
	PLATFORM_CROSS_SELL_TOGGLE,
	PLATFORM_CROSS_SELL_TOGGLE_ORDER_OPTIMIZATION,
	PLATFORM_VIEW_CROSS_SELL_THANKYOU,
} from "../../events";
import useIdentity from "../../hooks/useIdentity";

import "./cross-sell-module.css";
import {
	Asset,
	Button,
	CheckIcon,
	CheckboxElement,
	ChevronDownIcon,
	Dropdown,
	FONT_COLOR,
	Loader,
	PaddedContainer,
	RemoteButton,
	SPACING,
	STATUS,
	StatusLabel,
	isFunction,
	parseError,
	useResource,
} from "@disco/disco_core";
import { updateUser } from "../../utils/userManipulators";
import useViewTrack from "../../hooks/useViewTrack";
import ExtensionSetupInstructions from "../ExtensionSetupInstructions";
import hasMigratedToExtensions from "../../utils/hasMigratedToExtensions";

const CrossSellModuleSettings = memo(({ editMode, triggerRefresh }) => {
	const { user, setUser } = useIdentity();
	const shouldShowExtensionInstructions = hasMigratedToExtensions({
		user,
		considerOverride: true,
	});

	return (
		<>
			{shouldShowExtensionInstructions && (
				<ExtensionSetupInstructions marginBottom={SPACING.REGULAR} />
			)}
			<WidgetModuleToggler
				user={user}
				setUser={setUser}
				moduleName={user.isNewIa ? "DiscoFeed" : "Cross-Sell"}
				remoteKey="visible_on_network"
				toggleUrl={SAVE_NETWORK_URL}
				onToggle={triggerRefresh}
				event={PLATFORM_CROSS_SELL_TOGGLE}
			/>
			{user.publisher?.compute_recommendations && (
				<EditView
					url={SAVE_BRAND_URL}
					remoteKey="recommended_cross_sell_order"
					name="Disco Ordering Optimization "
					user={user}
					setUser={setUser}
					onEdit={triggerRefresh}
					toggle
					toggleEvent={PLATFORM_CROSS_SELL_TOGGLE_ORDER_OPTIMIZATION}
					tooltipTitle="What does this do?"
					tooltipText="Enable this to let Disco re-order cross-sell products to maximize sales"
				/>
			)}
			{/* {isShopifyShop(user) && (
					<EditView
						url={TOGGLE_PERMACART_URL}
						remoteKey="cross_sell_populate_cart"
						name="Cross-sell cart permalink"
						user={user}
						setUser={setUser}
						toggle
						tooltipTitle="What does this do?"
						tooltipText="Enable permalinks to take customers directly to a checkout cart from a Disco designed PDP."
					/>
				)} */}
			<EditView
				user={user}
				setUser={setUser}
				url={SAVE_HEADER_URL}
				remoteKey="header"
				name="Your widget header"
				draftTooltipName="widget header"
				onEdit={triggerRefresh}
				placeholder="Title for your widget"
				required
				draftable
				tooltipTitle="What does this do?"
				tooltipText="This header appears at the top of your post-purchase widget (e.g. “Brands we love”)"
			/>
			{/* <CategoryOptInModal
					user={user}
					setUser={setUser}
					editMode={editMode}
					onClose={triggerRefresh}
				/> */}
			<SubCategoryOptOutModalSelector
				user={user}
				setUser={setUser}
				editMode={editMode}
				onClose={triggerRefresh}
			/>
			<BrandOptOutModalSelector
				user={user}
				setUser={setUser}
				editMode={editMode}
				onClose={triggerRefresh}
			/>
		</>
	);
});

const CrossSellModuleOrdering = memo(
	({ user, setUser, editMode, setEditMode, triggerRefresh }) => {
		return (
			<ProductsContainer
				crossSellOnly={true}
				user={user}
				setUser={setUser}
				setRefresh={triggerRefresh}
				editMode={editMode}
				setEditMode={setEditMode}
			/>
		);
	}
);

const ToggleVisibility = () => {
	const { user, setUser } = useIdentity();
	const value = useMemo(
		() => user.publisher?.visible_as_publisher,
		[user.publisher]
	);
	const payload = useMemo(() => ({ visible_as_publisher: !value }), [value]);
	const handleDone = useCallback(
		({ data }) =>
			updateUser({ setUser, data, key: "visible_as_publisher" }),
		[setUser]
	);

	return (
		<RemoteButton
			url={SAVE_PUBLISHER_URL}
			payload={payload}
			vPadding={SPACING.TINY}
			hPadding={SPACING.REGULAR}
			onDone={handleDone}
		>
			{value ? (
				<>
					<Asset size={15} marginRight={SPACING.TINY}>
						<CheckIcon />
					</Asset>
					Published
				</>
			) : (
				"Publish"
			)}
		</RemoteButton>
	);
};

const CrossSellModule = memo(() => {
	const { user, setUser } = useIdentity();
	const [editMode, setEditMode] = useState(user.publisher.under_review);
	const [checkedOptions, setCheckedOptions] = useState({});
	const [success, setSuccess] = useState(false);
	const closeRef = useRef(null);

	const publishOptions = useMemo(
		() => ({
			visible_as_publisher: {
				heading: `Publish the ${
					user.isNewIa ? "DiscoFeed" : "Disco Carousel"
				} on my page`,
				text: user.publisher.visible_as_publisher
					? `Your  ${
							user.isNewIa ? "DiscoFeed" : "Disco Carousel"
					  } is live`
					: `Your  ${
							user.isNewIa ? "DiscoFeed" : "Disco Carousel"
					  } is not live yet`,
				checked: user.publisher.visible_as_publisher,
			},
			notify: {
				heading: user?.publisher?.verification_notification_sent
					? "Your account has been submitted for review"
					: "Submit my account & products for Disco to review",
				text: user.publisher.verified
					? "Your products are live"
					: "Your products are not live yet",
				checked: user.publisher.verification_notification_sent,
				disableCheck: user.publisher.verification_notification_sent,
			},
		}),
		[user]
	);

	const [{ loading, error, data }, save, reset] = useResource(
		{
			url: SAVE_PUBLISHER_URL,
			method: "PUT",
			data: publishOptions
				? Object.keys(publishOptions).reduce(
						(data, key) => ({
							...data,
							[key]: checkedOptions[key] ?? false,
						}),
						{}
				  )
				: {},
		},
		false
	);

	useEffect(() => {
		setCheckedOptions(
			publishOptions
				? Object.entries(publishOptions).reduce(
						(data, [key, option]) => ({
							...data,
							[key]: option.checked ?? false,
						}),
						{}
				  )
				: {}
		);
	}, [publishOptions]);

	useEffect(() => {
		if (!data) return;
		if (isFunction(closeRef.current)) {
			closeRef.current();
		}
		if (typeof setUser === "function") {
			setUser((user) => ({
				...user,
				publisher: {
					...user.publisher,
					...data,
				},
			}));
		}
		reset();
		setSuccess(data.visible_as_publisher);

		setTimeout(() => {
			setSuccess(false);
		}, 2000);
	}, [data, reset, checkedOptions, setUser]);

	const handleChange = useCallback((name) => {
		setCheckedOptions((options) => ({
			...options,
			[name]: !options[name],
		}));
	}, []);

	const renderOptions = useCallback(
		({ options, close }) => {
			if (!closeRef.current) {
				closeRef.current = close;
			}
			return (
				<PaddedContainer vPadding={SPACING.REGULAR} tightTop>
					{Object.entries(options).map(([key, option]) => (
						<PaddedContainer
							key={key}
							onClick={() => {
								if (loading || option?.disableCheck) return;
								handleChange(key);
							}}
							className="cross-sell-module-header-checkbox"
						>
							<CheckboxElement
								title={option.heading}
								subtitle={option.text}
								checked={checkedOptions[key] ?? false}
								name={key}
								disabled={loading || option?.disableCheck}
							></CheckboxElement>
						</PaddedContainer>
					))}
					<PaddedContainer centerContent>
						<Button onClick={save} disabled={loading}>
							{loading ? <Loader small light /> : "Publish"}
						</Button>
					</PaddedContainer>
					{error && (
						<PaddedContainer
							hPadding={SPACING.REGULAR}
							marginTop={SPACING.REGULAR}
						>
							<StatusLabel type={STATUS.ERROR}>
								{parseError(error)}
							</StatusLabel>
						</PaddedContainer>
					)}
				</PaddedContainer>
			);
		},
		[checkedOptions, error, handleChange, loading, save]
	);

	useViewTrack(PLATFORM_VIEW_CROSS_SELL_THANKYOU);

	const renderSettings = useCallback(
		(props) => <CrossSellModuleSettings editMode={editMode} {...props} />,
		[editMode]
	);

	const renderOrdering = useCallback(
		(props) => (
			<CrossSellModuleOrdering
				user={user}
				setUser={setUser}
				editMode={editMode}
				setEditMode={setEditMode}
				{...props}
			/>
		),
		[user, setUser, editMode]
	);

	const tabOptions = useMemo(() => {
		let options = [
			{
				name: user?.isNewIa ? "Manage DiscoFeed" : "Manage Cross-Sell",
				component: renderSettings,
			},
			{
				name: "Manage Product Ordering",
				component: renderOrdering,
				showCrossSellProducts: true,
			},
		];
		if (!user.publisher.recommended_cross_sell_order) {
			return options;
		}
		return options.slice(0, 1);
	}, [renderSettings, renderOrdering, user]);

	useEffect(() => {
		if (user.publisher.under_review) {
			setEditMode(true);
		}
	}, [user.publisher.under_review]);

	return (
		<>
			<section
				className="cross-sell-module-wrapper"
				data-testid="cross-sell-module"
			>
				<header className="cross-sell-module-toggler-header">
					{user.publisher.verified ? (
						<ToggleVisibility />
					) : (
						<Dropdown
							className="cross-sell-module-header-publish"
							options={publishOptions}
							renderOptions={renderOptions}
							activator={
								<Button
									className="cross-sell-module-header-publish-btn"
									vPadding={SPACING.TINY}
									hPadding={SPACING.REGULAR}
								>
									{success && (
										<Asset
											size={16}
											marginRight={SPACING.TINY}
										>
											<CheckIcon />
										</Asset>
									)}
									{success ? "Published" : "Publish"}
									<Asset color={FONT_COLOR.WHITE} size={20}>
										<ChevronDownIcon />
									</Asset>
								</Button>
							}
							placeholder=""
							value={""}
							multiple={false}
							hideToggleIcon
						/>
					)}
				</header>

				<WidgetModule
					user={user}
					setUser={setUser}
					tabOptions={tabOptions}
					heading={
						user?.isNewIa ? "DiscoFeed" : "Cross-Sell Partners"
					}
					className="new-cross-sell-module"
					remoteKey="visible_on_network"
					toggleUrl={SAVE_NETWORK_URL}
					previewType={PREVIEW_TYPES_IDX.CROSS_SELL}
				/>
			</section>
		</>
	);
});

export default CrossSellModule;
