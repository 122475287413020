import React, { memo, useCallback, useMemo, useState, useEffect } from "react";
import {
	PaddedContainer,
	ExternalLinkIcon,
	MoreHorizontalIcon,
	SPACING,
	Asset,
	Label,
	CheckCircleIcon,
	CrossIcon,
	StatusLabel,
	Text,
	Loader,
	InputContainer,
	FONT_SIZE,
	STATUS,
	parseError,
	DropdownSelect,
	FONT_COLOR,
} from "@disco/disco_core";

import {
	FETCH_KLAVIYO_OAUTH_INITIATE_URL,
	KLAVIYO_OAUTH_DISCONNECT_URL,
} from "../../conf";

import useIdentity from "../../hooks/useIdentity";
import useResource from "../../hooks/useResource";
import { GET } from "../../utils/GET";

import KLAVIYO_ICON from "../../img/klaviyo.svg";
import "./lead-offers-klaviyo-config.css";

const LeadOffersKlaviyoConfigManagerInfo = memo(({ setAlertStatus }) => {
	const { setUser } = useIdentity();
	const [{ loading, error, data }, disconnect] = useResource(
		{
			url: KLAVIYO_OAUTH_DISCONNECT_URL,
			method: "DELETE",
		},
		false
	);

	const handleDisconnect = useCallback(() => disconnect(), [disconnect]);

	useEffect(() => {
		if (!error) return;
		setAlertStatus({
			type: STATUS.ERROR,
			children: parseError(error),
		});
	}, [error, setAlertStatus]);

	useEffect(() => {
		if (!data) return;
		setUser((user) => ({
			...user,
			publisher: {
				...user.publisher,
				...data,
			},
		}));
		setAlertStatus({
			type: STATUS.SUCCESS,
			children:
				"Your Klaviyo integration has been disconnected successfully",
		});
	}, [data, setUser, setAlertStatus]);

	const moreOptions = useMemo(
		() => ({
			disconnect: {
				text: (
					<Text color={"--disco-red-mid"} size={FONT_SIZE.BODY} thick>
						Disconnect
					</Text>
				),
				action: handleDisconnect,
			},
		}),
		[handleDisconnect]
	);
	const dropdownActivator = useMemo(() => <MoreHorizontalIcon />, []);

	return (
		<PaddedContainer flexContent alignContentCenter>
			<Asset
				size={20}
				color={"--disco-green-mid"}
				marginRight={SPACING.TINY}
			>
				<CheckCircleIcon />
			</Asset>
			<Text
				color={"--disco-green-mid"}
				size={FONT_SIZE.BODY}
				marginRight={SPACING.REGULAR}
			>
				Connected
			</Text>
			{loading ? (
				<Loader small />
			) : (
				<DropdownSelect
					options={moreOptions}
					activator={dropdownActivator}
					hideToggleIcon
					value={""}
					className="lead-offers-klaviyo-config-manager-info-dropdown"
				/>
			)}
		</PaddedContainer>
	);
});
const LeadOffersKlaviyoConfigManagerInit = memo(({ setAlertStatus }) => {
	const [isRedirectLoading, setIsRedirectLoading] = useState(false);
	const [{ loading, error, data }, initConnection] = useResource(
		{
			url: FETCH_KLAVIYO_OAUTH_INITIATE_URL,
			method: "GET",
		},
		false
	);

	const handleClick = useCallback(() => {
		setAlertStatus(null);
		initConnection();
	}, [setAlertStatus, initConnection]);

	useEffect(() => {
		if (!error) return;
		setAlertStatus({
			type: STATUS.ERROR,
			children: parseError(error),
		});
	}, [error, setAlertStatus]);

	useEffect(() => {
		if (!data || !data?.redirect_url) return;
		setIsRedirectLoading(true);
		window.location.href = data.redirect_url;
	}, [data, setIsRedirectLoading]);

	if (loading || isRedirectLoading) return <Loader small />;

	return (
		<Text
			Element="section"
			flexContent
			alignContentCenter
			color={"--disco-theme-mid"}
			clickable
			onClick={handleClick}
			disabled={!!data?.redirect_url}
		>
			<Text size={FONT_SIZE.BODY} thick marginRight={SPACING.TINY}>
				Connect
			</Text>
			<Asset size={20}>
				<ExternalLinkIcon />
			</Asset>
		</Text>
	);
});

const KLAVIYO_OAUTH_SUCCESS_PARAM_KEY = "klaviyo_oauth_success";
const KLAVIYO_OAUTH_ERR_MESSAGE_PARAM_KEY = "klaviyo_oauth_error_message";
const LeadOffersKlaviyoConfigManager = memo(({ setAlertStatus }) => {
	const { user } = useIdentity();

	const isKlaviyoConnected = useMemo(
		() => user.publisher.is_klaviyo_connected,
		[user.publisher]
	);

	const klaviyoOauthParams = useMemo(
		() => ({
			success:
				GET(KLAVIYO_OAUTH_SUCCESS_PARAM_KEY) === "true"
					? true
					: GET(KLAVIYO_OAUTH_SUCCESS_PARAM_KEY) === "false"
					? false
					: null,
			errorMessage: GET(KLAVIYO_OAUTH_ERR_MESSAGE_PARAM_KEY),
		}),
		[]
	);

	useEffect(() => {
		const { success, errorMessage } = klaviyoOauthParams;

		if (success === true) {
			setAlertStatus({
				type: STATUS.SUCCESS,
				children:
					"Klaviyo <> Disco integration connected successfully!",
			});
			return;
		}

		if (success === false) {
			setAlertStatus({
				type: STATUS.ERROR,
				children:
					errorMessage || "OAuth failed. Please retry Klaviyo setup.",
			});
			return;
		}
	}, [klaviyoOauthParams, setAlertStatus]);

	return (
		<PaddedContainer>
			{isKlaviyoConnected ? (
				<LeadOffersKlaviyoConfigManagerInfo
					setAlertStatus={setAlertStatus}
				/>
			) : (
				<LeadOffersKlaviyoConfigManagerInit
					setAlertStatus={setAlertStatus}
				/>
			)}
		</PaddedContainer>
	);
});

const LeadOffersKlaviyoConfig = memo(() => {
	const [alertStatus, setAlertStatus] = useState(null);

	const handleAlertClose = useCallback(() => setAlertStatus(null), []);

	return (
		<PaddedContainer className="lead-offers-klaviyo-config">
			<Label uppercase color={FONT_COLOR.MID}>
				Connect Klaviyo
			</Label>
			<InputContainer
				flexContent
				marginTop={SPACING.TINY}
				hPadding={SPACING.MEDIUM}
				vPadding={SPACING.LARGE}
				centerContent
			>
				<Asset marginRight={"auto"}>
					<img src={KLAVIYO_ICON} alt="" />
				</Asset>
				<LeadOffersKlaviyoConfigManager
					setAlertStatus={setAlertStatus}
				/>
			</InputContainer>
			{alertStatus && (
				<StatusLabel
					marginTop={SPACING.TINY}
					noBorder
					className="lead-offers-klaviyo-config-status-label"
					type={alertStatus?.type || STATUS.WARNING}
				>
					<PaddedContainer flexContent alignContentCenter>
						<Text>{alertStatus?.children}</Text>
						<Asset
							clickable
							onClick={handleAlertClose}
							size={20}
							marginLeft={"auto"}
						>
							<CrossIcon />
						</Asset>
					</PaddedContainer>
				</StatusLabel>
			)}
		</PaddedContainer>
	);
});

export default LeadOffersKlaviyoConfig;
