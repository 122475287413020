import { PaddedContainer, SPACING, TitleRow } from "@disco/disco_core";
import React, { memo } from "react";
import "./audiences-overview-module.css";

const AudiencesOverviewModule = memo(
	({ heading, subHeading, children, ...rest }) => {
		return (
			<PaddedContainer marginTop={SPACING.LARGE} {...rest}>
				<TitleRow title={heading} subtitle={subHeading} />
				{children}
			</PaddedContainer>
		);
	}
);

export default AudiencesOverviewModule;
