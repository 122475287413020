import React, {
	memo,
	useState,
	forwardRef,
	useEffect,
	useRef,
	useMemo,
	useCallback,
	ReactNode,
	Dispatch,
	SetStateAction,
	ChangeEvent,
	ForwardedRef,
} from "react";
import "cropperjs/dist/cropper.css";
import Cropper, { ReactCropperProps } from "react-cropper";
import { FileInput } from "../FileInput";
import { isImage } from "../../utils";

import "./image-input.css";
import { IMAGE_TYPES, MAX_IMG_SIZE } from "../../conf";
import { StatusLabel } from "../StatusLabel";
import { FONT_COLOR, FONT_SIZE, Text } from "../Typography";
import { PaddedContainer, SPACING } from "../PaddedContainer";
import genPackageClassName from "../../utils/genPackageClassName";
import { SquareImgTile } from "../ImgTile";
import { StandardComponentWithChildren } from "../../shared.types";
export type ImageInputProps = {
	onChange?: (image: File | Blob, name: string) => void;
	aspect?: number;
	body?: ReactNode;
	value?: string;
	supportGif?: boolean;
	gifWidth?: string;
	newValue?: string;
	setNewValue?: Dispatch<SetStateAction<string>>;
	cropperProps?: ReactCropperProps;
};

type ImageInputComponent = StandardComponentWithChildren<
	ImageInputProps & { ref?: ForwardedRef<HTMLInputElement> }
>;

export const ImageInput: ImageInputComponent = memo(
	forwardRef(
		(
			{
				onChange,
				aspect = 1,
				children,
				value,
				supportGif = true,
				gifWidth = "80%",
				newValue,
				setNewValue,
				cropperProps,
				...rest
			},
			ref?: ForwardedRef<HTMLInputElement>
		) => {
			const [error, setError] = useState("");
			const [imageURL, setImageURL] = useState<string | null | undefined>(
				value
			);
			const [imageName, setImageName] = useState("");
			const [isGIF, setIsGIF] = useState(false);
			const cropRef = useRef<Cropper | null>(null);

			const usableImageTypes = useMemo(
				() =>
					supportGif
						? IMAGE_TYPES
						: IMAGE_TYPES.filter((type) => type !== "gif"),
				[supportGif]
			);

			useEffect(() => {
				if (newValue) {
					setImageURL(newValue);
					setNewValue && setNewValue("");
				}
			}, [newValue, setNewValue]);

			useEffect(
				() => () => {
					imageURL ? URL.revokeObjectURL(imageURL) : null;
					return;
				},
				[imageURL]
			);

			const handleChange = useCallback(
				(image: File) => {
					setError("");
					if (!image) {
						return setImageURL(null);
					}
					if (!isImage(image, usableImageTypes)) {
						return setError("Please select an image");
					}
					if (image.size / 10e5 > Number(MAX_IMG_SIZE)) {
						return setError(
							`The image can not be larger than ${MAX_IMG_SIZE}MB`
						);
					}

					// disabling cropper for GIF
					const { type: imageType, name } = image;
					setIsGIF(imageType === "image/gif");
					//--------
					setImageURL(URL.createObjectURL(image));
					setImageName(name);
					if (typeof onChange !== "function") return;
					onChange(image, name);
				},
				[onChange, usableImageTypes]
			);

			const handleImageUpdate = () => {
				if (!cropRef.current) {
					return;
				}
				cropRef.current
					.getCroppedCanvas()
					.toBlob(
						(file: Blob | null) =>
							file && typeof onChange === "function"
								? onChange(file, imageName)
								: null,
						"image/jpg"
					);
			};

			const initCrop: (instance: Cropper) => void | Promise<void> = (
				crop
			) => {
				cropRef.current = crop;

				const element = (
					crop as unknown as { element: HTMLImageElement }
				).element;

				element.addEventListener("ready", handleImageUpdate);
				element.addEventListener("cropend", handleImageUpdate);
			};

			const preventDefault = useCallback(
				(e: DragEvent) => e.preventDefault(),
				[]
			);
			const handleDrop = useCallback(
				(e: DragEvent) => {
					preventDefault(e);
					e.dataTransfer &&
						e.dataTransfer.files &&
						e.dataTransfer.files[0] &&
						handleChange(e.dataTransfer.files[0]);
				},
				[preventDefault, handleChange]
			);

			const acceptableFileInputTypes = useMemo(
				() => usableImageTypes.map((type) => `image/${type}`).join(","),
				[usableImageTypes]
			);
			const handleFileInputChange = useCallback(
				(e: ChangeEvent<HTMLInputElement>) =>
					e.target.files &&
					e.target.files[0] &&
					handleChange(e.target.files[0]),
				[handleChange]
			);

			return (
				<PaddedContainer
					className={genPackageClassName({ base: "image-input" })}
					onDrop={handleDrop}
					onDragOver={preventDefault}
				>
					<FileInput
						{...rest}
						accept={acceptableFileInputTypes}
						onChange={handleFileInputChange}
						ref={ref}
					/>

					{imageURL && !isGIF && (
						<Cropper
							src={imageURL}
							className={genPackageClassName({
								base: "image-input-cropper",
							})}
							aspectRatio={aspect}
							guides={true}
							autoCropArea={1}
							rotatable={true}
							zoomOnWheel={false}
							onInitialized={initCrop}
							minCropBoxWidth={100}
							minCropBoxHeight={100}
							{...cropperProps}
						/>
					)}
					{imageURL && isGIF && (
						<>
							<SquareImgTile
								src={imageURL}
								className={genPackageClassName({
									base: "image-input-gif-preview",
								})}
								alt="GIF Image"
								marginTop={SPACING.REGULAR}
								hMarginAuto
								width={gifWidth}
							/>
							<Text
								color={FONT_COLOR.MID}
								thick
								center
								size={FONT_SIZE.BODY}
								vPadding={SPACING.REGULAR}
								tightBottom
							>
								This file can not be cropped as it is a GIF
							</Text>
						</>
					)}

					{children ? (
						children
					) : (
						<Text
							className={genPackageClassName({
								base: "image-input-desc",
							})}
							color={FONT_COLOR.MID}
							size={FONT_SIZE.BODY}
							marginTop={SPACING.REGULAR}
						>
							<Text marginBottom={SPACING.SMALL}>
								General Requirements
							</Text>
							{aspect === 1 && (
								<Text>
									For best results, attach a square image: 1:1
								</Text>
							)}
							Supported formats:{" "}
							<Text thick inline color={FONT_COLOR.DARK}>
								{supportGif
									? "PNG, JPEG/JPG & GIF"
									: "PNG, JPEG & JPG"}
							</Text>
							<br />
							Max file size:{" "}
							<Text thick inline color={FONT_COLOR.DARK}>
								{MAX_IMG_SIZE}MB
							</Text>
						</Text>
					)}

					{error && (
						<StatusLabel
							className={genPackageClassName({
								base: "image-input-status-label",
							})}
							marginTop={SPACING.SMALL}
						>
							{error}
						</StatusLabel>
					)}
				</PaddedContainer>
			);
		}
	)
);
