import React, {
	createRef,
	memo,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import FloatingModal from "./FloatingModal";
import "./discount-modal.css";
import { BRAND_INFO_URL, FETCH_BRAND_INFO_URL } from "../../conf";
import { GuidedForm, Button, Loader, FormStatus, ShopifyDot } from "../CoreUI";
import useResource from "../../hooks/useResource";
import parseError from "../../utils/parseError";
import isShopifyShop from "../../utils/isShopifyShop";
import useModal from "../../hooks/useModal";
import Alert from "./Alert";
import { MdCheck } from "react-icons/md";

const getMessageTooltip = (user, code) =>
	`${
		isShopifyShop(user) ? "This must contain your discount code. " : ""
	}For example: "Enter ${code ? code : "DISCO15"} at checkout for 15% off"`;

const DiscountModal = memo(({ user, setUser, onClose, ...rest }) => {
	const {
		open: alertOpen,
		handleClose: handleAlertClose,
		handleOpen: handleAlertOpen,
	} = useModal();

	const [success, setSuccess] = useState(false);

	const removeRef = useRef(false);

	const [data, setData] = useState({
		product_discount: {
			props: {
				type: "number",
				label: isShopifyShop(user)
					? "Global Discount (%)"
					: "Default Product Discount",
				ref: createRef(),
				unit: "%",
				placeholder: "%",
				value: user.publisher.product_discount,
			},
			tooltip: {
				heading: "Percentage Discount",
				text: "The percentage off applied to all products when using the configured discount code",
			},
		},
		...(isShopifyShop(user)
			? {
					code: {
						props: {
							type: "text",
							label: "Shop Discount Code",
							ref: createRef(),
							placeholder: "DISCO15",
							value: user.publisher.code || "",
						},
						tooltip: {
							heading:
								"The code customers use to avail your discounts",
							text: "Per Shopify’s guidelines please avoid using special characters in your discount names so that they add correctly to your store’s checkout URL",
						},
					},
			  }
			: {}),

		message: {
			props: {
				label: "Discount message",
				ref: createRef(),
				textarea: true,
				placeholder: "Enter DISCO15 at checkout for 15% off",
				value: user.publisher.message,
			},
			tooltip: {
				heading: "What should customers see when viewing your offer?",
				text: getMessageTooltip(user, user.publisher.code),
			},
		},
	});

	const [payload, setPayload] = useState({});
	const [formError, setFormError] = useState(false);

	const [{ loading, data: saveData, error }, save, reset] = useResource(
		{
			url: BRAND_INFO_URL,
			method: "put",
			data: payload,
		},
		false
	);

	const handleSave = useCallback(
		(remove = false) => {
			handleAlertClose();
			removeRef.current = remove;

			let disc = data.product_discount?.props?.value,
				code = data.code?.props?.value,
				message = data.message?.props?.value;
			if (isShopifyShop(user) && !remove) {
				if (/[^A-Za-z0-9 ]/.test(code.trim())) {
					setFormError(
						"Discount code can only contain numbers and alphabets"
					);
					return;
				}

				if (
					message.toLowerCase().search(code.toLowerCase()) === -1 &&
					!remove
				) {
					setFormError(
						`The discount message should contain the code '${code}'`
					);
					return;
				}
			}

			if (
				disc &&
				(isNaN(disc) || Number(disc) < 0 || Number(disc) > 100) &&
				!remove
			) {
				setFormError("Please enter discount percent between 0 and 100");
				return;
			} else if (!disc) {
				disc = "";
			}
			setFormError("");
			setPayload(
				remove
					? { code: null, message: null, product_discount: null }
					: {
							...(isShopifyShop(user) ? { code } : {}),
							message,
							product_discount: disc,
					  }
			);
			save();
		},
		[save, data, user, handleAlertClose]
	);

	useEffect(() => {
		if (!saveData) {
			return;
		}
		if (removeRef.current) {
			setData((data) => {
				const copy = Object.entries(data).reduce(
					(acc, [key, field]) => ({
						...acc,
						[key]: {
							...field,
							props: { ...field.props, value: "" },
						},
					}),
					{}
				);

				return copy;
			});
		}

		setUser((user) => ({
			...user,
			publisher: { ...user.publisher, ...saveData.brand },
		}));
		setSuccess(true);
		setTimeout(() => {
			setSuccess(false);
		}, 2000);
	}, [saveData, setUser, reset, onClose]);

	const handleSaveClick = useCallback(() => {
		if (isShopifyShop(user)) {
			handleAlertOpen();
		} else {
			handleSave();
		}
	}, [handleAlertOpen, user, handleSave]);

	const handleRemoveClick = useCallback(() => {
		handleSave(true);
	}, [handleSave]);

	return (
		<FloatingModal
			{...rest}
			className="discount-modal"
			heading={
				<>
					Configure Discounts{" "}
					{isShopifyShop(user) && (
						<ShopifyDot
							yellow={
								!user.publisher.code ||
								!user.publisher.product_discount
							}
						/>
					)}
				</>
			}
			onClose={onClose}
			closer={
				<section className="discount-modal-closer">
					{!loading &&
						(success ? (
							<section className="discount-modal-success">
								<span>
									<MdCheck />
								</span>
								{removeRef.current
									? "The code was removed."
									: "The code is setup!"}
							</section>
						) : (
							<>
								<Button light onClick={onClose}>
									Close
								</Button>
								{(user.publisher.code ||
									user.publisher.message) && (
									<Button light onClick={handleRemoveClick}>
										Remove Code
									</Button>
								)}
								<Button gradient onClick={handleSaveClick}>
									Save
								</Button>
							</>
						))}
					{loading && <Loader />}
				</section>
			}
		>
			{(error || formError) && (
				<FormStatus>{error ? parseError(error) : formError}</FormStatus>
			)}

			<GuidedForm
				data={data}
				setData={setData}
				initialHighlight="product_discount"
			/>
			<Alert
				open={alertOpen}
				onClose={handleAlertClose}
				onDone={handleSave}
				heading="Shopify discounts for all products"
				text="This global discount code will be activated for all Shopify products. Do you wish to proceed?"
			></Alert>
		</FloatingModal>
	);
});

export default DiscountModal;
