import React, { memo, useMemo } from "react";

import heading from "../../img/audiences/info-heading.svg";

import "./audiences-info.css";

import settingsIcon from "../../img/audiences/settings-icon-clean.png";
import phoneIcon from "../../img/audiences/phone-icon-clean.png";
import cartIcon from "../../img/audiences/cart-icon-clean.png";
import speakerIcon from "../../img/audiences/speaker-icon.png";
import performanceIcon from "../../img/audiences/performance-icon.png";

import { AUDIENCES_TABS } from "../feedModules/AudiencesModule";
import useContent from "../../hooks/useContent";
import parseRichContent from "../../utils/parseRichContent";
import { N_AUDIENCES_FEATURES } from "../../conf";
import {
	anim,
	Button,
	FONT_COLOR,
	FONT_SIZE,
	PaddedContainer,
	SPACING,
	Text,
} from "@disco/disco_core";

const AudiencesInfo = memo(({ changeTab }) => {
	const content = useContent().platformAudiences;
	const features = useMemo(() => {
		const features = [];
		for (let i = 0; i < N_AUDIENCES_FEATURES; ++i) {
			features.push({
				heading: content[`sequenceHeading${i + 1}`],
				text: content[`sequenceText${i + 1}`],
				icon: settingsIcon,
			});
		}

		features[0].icon = phoneIcon;
		features[1].icon = cartIcon;
		features[2].icon = speakerIcon;
		features[3].icon = performanceIcon;

		return features;
	}, [content]);

	return (
		<PaddedContainer
			motionElement
			Element="main"
			className="audiences-info"
			variants={anim.simplePageContent}
			initial="initial"
			animate="animate"
			data-testid="audiences-info"
			hPadding={SPACING.LARGE}
			vPadding={SPACING.LARGE}
			marginTop={SPACING.MEDIUM}
		>
			<Text thin size={FONT_SIZE.SUB_TITLE} color={FONT_COLOR.MID}>
				{content.worksSubheading}
			</Text>
			<img
				src={heading}
				alt="Earn 2nd party audiences to retarget on various platforms"
			/>
			<Text thin size={FONT_SIZE.LG_BODY} color={FONT_COLOR.MID}>
				{parseRichContent(content.worksText)}
			</Text>
			<Button rounded onClick={() => changeTab(AUDIENCES_TABS.OVERVIEW)}>
				{content.worksCta}
			</Button>
			<PaddedContainer className="audiences-info-features">
				{features.map(({ icon, heading, text }, index) => (
					<PaddedContainer
						key={index}
						className="audiences-info-feature"
						data-testid={`audiences-info-feature-${index}`}
						marginTop={SPACING.LARGE}
					>
						<img src={icon} alt="" />
						<Text thick size={FONT_SIZE.LG_BODY}>
							{heading}
						</Text>
						<Text size={FONT_SIZE.BODY} color={FONT_COLOR.MID}>
							{text}
						</Text>
					</PaddedContainer>
				))}
			</PaddedContainer>
		</PaddedContainer>
	);
});

export default AudiencesInfo;
