import React, { memo, useState, useEffect, useCallback, useMemo } from "react";
import {
	Asset,
	Button,
	Card,
	Checkbox,
	ChevronDownIcon,
	ChevronUpIcon,
	FONT_COLOR,
	FONT_SIZE,
	genClassName,
	isFunction,
	Loader,
	PaddedContainer,
	SPACING,
	StatusLabel,
	Tag,
	Text,
	useResource,
} from "@disco/disco_core";

import "./audiences-category.css";
import { DEFAULT_ERROR_MESSAGE, FETCH_CATEGORIES_URL } from "../../conf";
import isArray from "../../utils/isArray";
import useTrack from "../../hooks/useTrack";
import {
	PLATFORM_AUDIENCES_CLICK_CATEGORY,
	PLATFORM_AUDIENCES_TOGGLE_CATEGORIES,
} from "../../events";

export const AudiencesCategoryHeading = memo(({ open, onToggle }) => {
	return (
		<PaddedContainer
			flexContent
			alignContentCenter
			onClick={onToggle}
			data-testid={`audiences-category-heading`}
			clickable
		>
			Target Shopper Category{" "}
			<Tag small marginLeft={SPACING.TINY}>
				Optional
			</Tag>
			<Asset size={20} color={FONT_COLOR.LIGHT} marginLeft={SPACING.TINY}>
				{open ? <ChevronUpIcon /> : <ChevronDownIcon />}
			</Asset>
		</PaddedContainer>
	);
});

const AudiencesCategoryBadge = memo(
	({ selected, disabled, onClick, children, id, name, remoteId }) => {
		const handleClick = useCallback(() => {
			if (disabled || !isFunction(onClick)) return;
			onClick({ id, name, remoteId });
		}, [disabled, onClick, id, name, remoteId]);

		const className = useMemo(
			() =>
				genClassName({
					base: "audiences-category-badge",
					conditionals: {
						"audiences-category-badge-selected": selected,
						"audiences-category-badge-disabled": disabled,
					},
				}),
			[selected, disabled]
		);

		return (
			<Card
				className={className}
				data-testid={className}
				clickable={!disabled}
				onClick={handleClick}
				marginRight={SPACING.SMALL}
				marginBottom={SPACING.REGULAR}
				hPadding={SPACING.MEDIUM}
				vPadding={SPACING.SMALL}
			>
				<Text size={FONT_SIZE.BODY}>{children}</Text>
			</Card>
		);
	}
);

const AudiencesCategory = memo(({ open, selected, setSelected }) => {
	const [active, setActive] = useState(false);

	const track = useTrack();

	const handleCheckboxChange = useCallback(
		({ target: { checked } }) => {
			track(PLATFORM_AUDIENCES_TOGGLE_CATEGORIES, { enabled: checked });
			setActive(checked);
		},
		[track]
	);

	const [{ data, loading, error }, fetch] = useResource({
		url: FETCH_CATEGORIES_URL,
	});

	const handleCategoryClick = useCallback(
		({ id, name, remoteId }) => {
			track(PLATFORM_AUDIENCES_CLICK_CATEGORY, {
				id,
				name,
				remote_id: remoteId,
			});
			setSelected(id);
		},
		[setSelected, track]
	);

	useEffect(() => {
		if (!active) return setSelected(null);
		if (!isArray(data) || data.length < 1) return;
		setSelected(data?.[0]?.id);
	}, [active, setSelected, data]);

	if (!open) return null;
	return (
		<PaddedContainer
			vPadding={SPACING.SMALL}
			data-testId="audiences-category"
		>
			<Text size={FONT_SIZE.BODY} color={FONT_COLOR.DARK}>
				By default we'll be targeting all shopper categories
			</Text>
			<PaddedContainer
				flexContent
				alignContentCenter
				marginTop={SPACING.SMALL}
			>
				<Checkbox
					checked={active}
					onChange={handleCheckboxChange}
					data-testid="audiences-category-checkbox"
				/>
				<Text
					size={FONT_SIZE.BODY}
					color={FONT_COLOR.DARK}
					marginLeft={SPACING.TINY}
				>
					Select a shopper category to target
				</Text>
			</PaddedContainer>
			<PaddedContainer
				marginTop={SPACING.TINY}
				className="audiences-category-badges"
				flexContent
			>
				{loading && <Loader middle marginTop={SPACING.MEDIUM} />}
				{error && (
					<PaddedContainer marginTop={SPACING.SMALL}>
						<StatusLabel>{DEFAULT_ERROR_MESSAGE}</StatusLabel>
						<Button marginTop={SPACING.TINY} small onClick={fetch}>
							Try again
						</Button>
					</PaddedContainer>
				)}
				{data &&
					data.map(({ id, name, remote_id }) => (
						<AudiencesCategoryBadge
							disabled={!active}
							selected={selected === id}
							onClick={handleCategoryClick}
							id={id}
							remoteId={remote_id}
							name={name}
							key={id}
						>
							{name}
						</AudiencesCategoryBadge>
					))}
			</PaddedContainer>
		</PaddedContainer>
	);
});
export default AudiencesCategory;
