import React, { memo, useCallback, useMemo } from "react";
import { BANNER_IMAGE_UPLOAD_URL, TOGGLE_POST_PURCHASE_URL } from "../../conf";
import { EditImage } from "../CoreUI";
import NewEditImage from "../NewEditImage";

import BrandColorEditView from "../BrandColorEditView";
import WidgetModule, { WidgetModuleToggler } from "./WidgetModule";

import "./post-purchase-module.css";

export const PostPurchaseModuleHome = memo(
	({ user, setUser, triggerRefresh, designSystem = false }) => {
		const bannerSelector = useCallback(
			(user) => user.publisher.banner_url,
			[]
		);
		const handleBannerDone = useCallback(
			(img) => {
				triggerRefresh();
				setUser((user) => ({
					...user,
					publisher: {
						...user.publisher,
						banner_url: img,
					},
				}));
			},
			[setUser, triggerRefresh]
		);

		return (
			<>
				<WidgetModuleToggler
					user={user}
					setUser={setUser}
					moduleName="Post Purchase Experience"
					remoteKey="post_purchase"
					toggleUrl={TOGGLE_POST_PURCHASE_URL}
					onToggle={triggerRefresh}
					designSystem={designSystem}
				/>
				<BrandColorEditView
					user={user}
					setUser={setUser}
					onUpdate={triggerRefresh}
					designSystem={designSystem}
				/>
				{designSystem ? (
					<NewEditImage
						user={user}
						aspect={3}
						remoteKey="banner_url"
						url={BANNER_IMAGE_UPLOAD_URL}
						editText="Change Banner"
						heading="Change Banner Image"
						onDone={handleBannerDone}
						selector={bannerSelector}
						className="new-post-purchase-module-banner"
					/>
				) : (
					<EditImage
						user={user}
						aspect={3}
						remoteKey="banner_url"
						url={BANNER_IMAGE_UPLOAD_URL}
						editText="Change Banner"
						heading="Change Banner Image"
						onDone={handleBannerDone}
						selector={bannerSelector}
						className="post-purchase-module-banner"
					/>
				)}
			</>
		);
	}
);

const PostPurchaseModule = memo(({ user, setUser }) => {
	const renderHome = useCallback(
		(props) => (
			<PostPurchaseModuleHome user={user} setUser={setUser} {...props} />
		),
		[user, setUser]
	);
	const tabOptions = useMemo(
		() => [
			{
				name: "Home",
				component: renderHome,
			},
		],
		[renderHome]
	);

	return (
		<WidgetModule
			user={user}
			setUser={setUser}
			tabOptions={tabOptions}
			heading="Post Purchase Experience"
			className="post-purchase-module"
		/>
	);
});

export default PostPurchaseModule;
