import { Button, Text } from "@disco/disco_core";
import React, {
	createContext,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { PopupModal } from "react-calendly";
import { LYDIA_CALENDLY_URL } from "../../conf";
import {
	PLATFORM_AUDIENCES_PREVIEW_SCHEDULE_CALL,
	PLATFORM_AUDIENCES_VIEW_HISTORY,
	PLATFORM_AUDIENCES_VIEW_OVERVIEW,
	PLATFORM_AUDIENCES_VIEW_WORKS,
} from "../../events";
import useContent from "../../hooks/useContent";
import useIdentity from "../../hooks/useIdentity";
import useModal from "../../hooks/useModal";
import useTrack from "../../hooks/useTrack";
import AudiencesInfo from "../AudiencesInfo";
import AudiencesOverview from "../AudiencesOverview";
import { PageHeader } from "../CoreUI";
import InlineToast from "../InlineToast";
import { AudiencesHistoryTab } from "../audiencesHistory";
// import { IS_BIG_SPEND_MERCHANT } from "../modals/AudiencesPaylModal"; BRND-483
import "./audiences-module.css";

export const audiencesHistoryContext = createContext();

export const AUDIENCES_TABS = {
	OVERVIEW: 0,
	HISTORY: 1,
	INFO: 2,
};

const AudiencesModule = memo(() => {
	const content = useContent().platformAudiences;
	const [tabs, setTabs] = useState([
		{
			name: "Overview",
			active: true,
			component: AudiencesOverview,
			viewEvent: PLATFORM_AUDIENCES_VIEW_OVERVIEW,
			"data-testid": "audiences-tabs-overview",
		},
		{
			name: "History",
			active: false,
			component: AudiencesHistoryTab,
			viewEvent: PLATFORM_AUDIENCES_VIEW_HISTORY,
			"data-testid": "audiences-tabs-history",
		},
		{
			name: "How it Works",
			active: false,
			component: AudiencesInfo,
			viewEvent: PLATFORM_AUDIENCES_VIEW_WORKS,
			"data-testid": "audiences-tabs-info",
		},
	]);

	const [history, setHistory] = useState({});

	const { user } = useIdentity();

	const changeTab = useCallback((activeTabIndex) => {
		setTabs((tabs) =>
			tabs.map((tab, index) => ({
				...tab,
				active: index === activeTabIndex,
			}))
		);
	}, []);

	const handleTabChange = useCallback(
		(name, index) => {
			setTabs((tabs) =>
				tabs.map((tab, idx) => {
					return { ...tab, active: tab.name === name };
				})
			);
		},
		[setTabs]
	);

	const ActiveComponent = useMemo(
		() => tabs.find((tab) => tab.active)?.component || null,
		[tabs]
	);

	const track = useTrack();

	useEffect(() => {
		const event = tabs.find((tab) => tab.active)?.viewEvent || null;
		if (!event) return;
		track(event);
	}, [tabs, track]);

	// const isBigSpend = IS_BIG_SPEND_MERCHANT({ user }); BRND-483

	const {
		open: calOpen,
		handleOpen: handleCalOpen,
		handleClose: handleCalClose,
	} = useModal();

	return (
		<audiencesHistoryContext.Provider value={{ history, setHistory }}>
			<PageHeader
				heading={content.pageHeading}
				tabOptions={tabs}
				handleTabChange={handleTabChange}
				className="audiences-page-header"
			/>
			{/* {!user.publisher.audience_list_eligibility && isBigSpend && (
				<InlineToast type="none" className="audiences-module-preview">
					<Text>
						<b>Disco Audiences Preview</b> While you wait to connect
						with your CSM
						{user.publisher.csm.csm_first_name
							? `, ${user.publisher.csm.csm_first_name}`
							: ""}
						, you can setup Audiences in preparation of going live.
					</Text>
					{isBigSpend && (
						<Button
							rounded
							small
							secondary
							onClick={() => {
								handleCalOpen();
								track(PLATFORM_AUDIENCES_PREVIEW_SCHEDULE_CALL);
							}}
						>
							Schedule call
						</Button>
					)}
				</InlineToast>
			)} BRND-483 */}
			<ActiveComponent changeTab={changeTab} />
			{/* <AudiencesPayModal /> BRND-483 */}
			<PopupModal
				open={calOpen}
				onModalClose={handleCalClose}
				rootElement={document.getElementById("root")}
				url={user.publisher.csm.csm_meeting_link || LYDIA_CALENDLY_URL}
			></PopupModal>
		</audiencesHistoryContext.Provider>
	);
});

export default AudiencesModule;
