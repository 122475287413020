import { PREVIEW_TYPES_IDX } from "../conf";

const getPreviewOptions = ({ user, showCrossSellProducts }) => {
	let _previewData = [
		{ title: "Full Flow", type: PREVIEW_TYPES_IDX.FULL },
		{
			title: user?.isNewIa ? "DiscoFeed" : "Cross-Sell",
			type: showCrossSellProducts
				? PREVIEW_TYPES_IDX.CROSS_SELL_PRODUCTS
				: PREVIEW_TYPES_IDX.CROSS_SELL,
			hideExtensionBtn: true,
		},
		{
			title: "Upsells",
			type: PREVIEW_TYPES_IDX.UPSELL,
			hideExtensionBtn: true,
		},
		{ title: "Surveys", type: PREVIEW_TYPES_IDX.ALL_SURVEY },
		{ title: "Disco Cash", type: PREVIEW_TYPES_IDX.ALL_REWARD },
	];

	return _previewData.filter((_previewDataObj) => !_previewDataObj.hidden);
};

export default getPreviewOptions;
