import React, { memo } from "react";
import { Button } from "../CoreUI";
import Modal from "./Modal";
import "./alert-modal.css";

const Alert = memo(
	({
		heading = "",
		text = "",
		buttonText = "",
		onDone,
		onClose,
		...rest
	}) => {
		return (
			<Modal
				heading={heading}
				onClose={onClose}
				className="alert-modal"
				{...rest}
			>
				<p>{text}</p>
				<section className="alert-modal-action">
					<Button
						onClick={() => {
							onDone();
							onClose();
						}}
					>
						{buttonText || "Yes"}
					</Button>
					<Button onClick={() => onClose()} light>
						Cancel
					</Button>
				</section>
			</Modal>
		);
	}
);

export default Alert;
