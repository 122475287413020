import React, { memo, useState, useMemo, useCallback, useEffect } from "react";
import {
	FACEBOOK_LOG_OUT_URL,
	FETCH_AD_ACCOUNT_INFO_URL,
	FETCH_ALL_FACEBOOK_AD_ACCOUNTS_URL,
	REQUESTABLE_AUDIENCE_SIZE,
	REQUEST_LOOKALIKE_AUDIENCE_URL,
	SET_AD_ACCOUNT_URL,
} from "../../conf";
import useResource from "../../hooks/useResource";
import readableInt from "../../utils/readableInt";
import { keyMapperFactory } from "../../utils/selectMappers";
import { Slider } from "../CoreUI";

import "./facebook-audience-manager.css";
import parseError from "../../utils/parseError";
import useModal from "../../hooks/useModal";
import FloatingModal from "../modals/FloatingModal";
import windowRedirect from "../../utils/windowRedirect";
import {
	anim,
	Button,
	ChevronLeftIcon,
	FONT_COLOR,
	FONT_SIZE,
	genClassName,
	Label,
	Loader,
	PaddedContainer,
	RemoteSelect,
	Select,
	SPACING,
	StatusLabel,
	Tag,
	TAG_TYPE,
	Text,
} from "@disco/disco_core";

const FACEBOOK_AD_ACCOUNT_CONFIG_COPIES = {
	1: {
		heading: "",
		description: "",
	},
	2: {
		heading: "User Terms of Service incomplete",
		description:
			"Please accept Facebook Custom Audiences Terms of Service for your own account",
	},
	3: {
		heading: "Ad Account Terms of Service incomplete",
		description:
			"Please accept Facebook Custom Audiences Terms of Service for your ad account",
	},
};
export const FacebookAdAccountConfiguratorModal = memo(
	({ user, setUser, handleClose, open, ...props }) => {
		const [selectValue, setSelectValue] = useState(
			user.facebook_ad_account_id || ""
		);
		const [{ data, error, loading }, fetch, reset] = useResource(
			{
				url: FETCH_ALL_FACEBOOK_AD_ACCOUNTS_URL,
				method: "GET",
			},
			false
		);

		const handleChange = useCallback(
			({ target: { value } }) => setSelectValue(value),
			[]
		);

		const currAccount = useMemo(
			() =>
				!data || !Array.isArray(data) || data?.length < 1
					? false
					: data.find((account) => account?.id === selectValue) ||
					  false,
			[data, selectValue]
		);

		const isErrorResolvable = useMemo(() => {
			if (!currAccount) {
				return null;
			}
			let isResolvable = true;
			currAccount?.errors?.forEach((error) => {
				isResolvable = isResolvable && error?.resolvable;
			});

			return isResolvable;
		}, [currAccount]);

		const renderRedirectButton = useCallback((url) => {
			const handleClick = () => windowRedirect(url);
			return (
				<Button secondary onClick={handleClick}>
					Configure
				</Button>
			);
		}, []);

		useEffect(() => {
			if (!open) {
				reset();
				return;
			}
			fetch();
		}, [open, reset, fetch]);

		return (
			<FloatingModal
				heading="Configure Ad Accounts"
				onClose={handleClose}
				className="facebook-ad-account-configurator-modal"
				open={open}
				{...props}
			>
				{loading ? (
					<Loader middle />
				) : error ? (
					<StatusLabel
						variants={anim.verticalSlide}
						initial="initial"
						animate="animate"
					>
						{parseError(error, true)}
					</StatusLabel>
				) : data ? (
					<PaddedContainer
						motionElement
						variants={anim.verticalSlide}
						initial="initial"
						animate="animate"
					>
						<Select
							options={keyMapperFactory("id")(data)}
							placeholder="Choose an account"
							label="Available ad accounts"
							value={selectValue}
							searchable={false}
							onChange={handleChange}
						/>

						{!selectValue ? null : currAccount?.errors?.length ===
						  0 ? (
							<StatusLabel
								type="success"
								variants={anim.verticalSlide}
								initial="initial"
								animate="animate"
							>
								You are all set up to use this account
							</StatusLabel>
						) : isErrorResolvable === false ? (
							<StatusLabel
								variants={anim.verticalSlide}
								initial="initial"
								animate="animate"
							>
								This is a personal account which cannot be used
								with Disco Audiences. <br /> Please select a
								different ad account
							</StatusLabel>
						) : (
							<PaddedContainer
								motionElement
								variants={anim.verticalSlide}
								initial="initial"
								animate="animate"
								className="facebook-ad-account-configurator-modal-actions"
							>
								<Label>Please complete the following</Label>
								{currAccount?.errors.map(
									({ resolution_link, code }) => (
										<PaddedContainer
											key={resolution_link}
											vPadding={SPACING.MEDIUM}
											className="facebook-ad-account-configurator-modal-action"
										>
											<Text size={FONT_SIZE.LG_BODY}>
												{
													FACEBOOK_AD_ACCOUNT_CONFIG_COPIES[
														code
													]?.heading
												}
											</Text>
											<PaddedContainer
												marginTop={SPACING.TINY}
												className="facebook-ad-account-configurator-modal-action-body"
											>
												<Text
													size={FONT_SIZE.BODY}
													color={FONT_COLOR.DARK}
													className="facebook-ad-account-configurator-modal-action-description"
												>
													{
														FACEBOOK_AD_ACCOUNT_CONFIG_COPIES[
															code
														]?.description
													}
												</Text>
												{renderRedirectButton(
													resolution_link
												)}
											</PaddedContainer>
										</PaddedContainer>
									)
								)}
							</PaddedContainer>
						)}
					</PaddedContainer>
				) : null}
			</FloatingModal>
		);
	}
);
const FacebookAdAccountConfigurator = memo(({ user, setUser }) => {
	const { open, handleOpen, handleClose } = useModal(false);
	return (
		<>
			<PaddedContainer
				hPadding={SPACING.MICRO}
				tightRight
				className="facebook-ad-account-configurator"
			>
				<Text inline size={FONT_SIZE.BODY}>
					Don't see your ad account?
				</Text>
				<Tag type={TAG_TYPE.THEME} onClick={handleOpen}>
					Configure
				</Tag>
			</PaddedContainer>
			<FacebookAdAccountConfiguratorModal
				user={user}
				setUser={setUser}
				open={open}
				handleClose={handleClose}
			/>
		</>
	);
});
export const FacebookAdAccountSelector = memo(
	({ user, setUser, showLogoutButton = false }) => {
		const handleAdAccountChange = useCallback(() => {}, []);

		const handleAdAccountSave = useCallback(
			({ target: { value } }) => {
				setUser((currUser) => ({
					...currUser,
					...value,
				}));
			},
			[setUser]
		);

		return (
			<PaddedContainer
				className="facebook-ad-account-selector"
				marginTop={SPACING.SMALL}
				marginBottom={SPACING.LARGE}
				marginLeft={SPACING.MICRO}
				marginRight={SPACING.MICRO}
			>
				<RemoteSelect
					fetchUrl={FETCH_AD_ACCOUNT_INFO_URL}
					mapper={keyMapperFactory("id")}
					placeholder="Choose an account"
					label="Facebook ad account"
					value={user.facebook_ad_account_id || false}
					multiple={false}
					saveUrl={SET_AD_ACCOUNT_URL}
					remoteKey="account_id"
					emptyMessage="No accounts found"
					searchable={false}
					onChange={handleAdAccountChange}
					onSave={handleAdAccountSave}
				/>
				<FacebookAdAccountConfigurator user={user} setUser={setUser} />
				{showLogoutButton && (
					<FacebookDisconnectButton user={user} setUser={setUser} />
				)}
			</PaddedContainer>
		);
	}
);

const FacebookAudienceCampaignRow = memo(({ name, url }) => {
	const handlePerformanceClick = useCallback(() => {
		const performanceWindow = window.open();
		if (!performanceWindow) {
			window.location.href = url;
			return;
		}
		performanceWindow.opener = null;
		performanceWindow.location = url;
	}, [url]);

	return (
		<PaddedContainer
			hPadding={SPACING.SMALL}
			vPadding={SPACING.SMALL}
			marginTop={SPACING.SMALL}
			className="facebook-audience-campaign"
		>
			<Text Element="h3">{name}</Text>
			<Tag type={TAG_TYPE.THEME} onClick={handlePerformanceClick}>
				View Performance
			</Tag>
		</PaddedContainer>
	);
});

const FacebookAudienceStatusCard = memo(({ audienceInfo }) => {
	const {
		audience: { name = "" } = {},
		status: { id: statusIdx, value: statusValue } = {},
		campaigns,
	} = useMemo(() => audienceInfo || {}, [audienceInfo]);

	if (!audienceInfo) {
		return <></>;
	}

	return (
		<PaddedContainer
			motionElement
			marginBottom={SPACING.SMALL}
			hPadding={SPACING.MICRO}
			vPadding={SPACING.MICRO}
			className="facebook-audience-status-card"
			variants={anim.variantFactory({
				y: 30,
				opacity: 0,
			})}
		>
			<PaddedContainer
				hPadding={SPACING.SMALL}
				vPadding={SPACING.SMALL}
				className="facebook-audience-status-card-header"
			>
				<Text Element="main" size={SPACING.BODY} thick>
					{name}
				</Text>
				<aside>
					<Tag>
						{statusIdx > 2 ? statusValue : "Request Initiated"}
					</Tag>
				</aside>
			</PaddedContainer>
			<PaddedContainer
				hPadding={SPACING.SMALL}
				vPadding={SPACING.SMALL}
				className="facebook-audience-status-card-body"
			>
				{Array.isArray(campaigns) && campaigns.length > 0 ? (
					campaigns.map((campaign) => (
						<>
							<FacebookAudienceCampaignRow
								key={campaign?.url}
								{...campaign}
							/>
						</>
					))
				) : (
					<Text
						element="h4"
						marginTop={SPACING.SMALL}
						marginBottom={SPACING.SMALL}
						marginLeft={SPACING.SMALL}
						marginRight={SPACING.SMALL}
					>
						Looks like you have no active campaigns with this
						audience at the moment
					</Text>
				)}
			</PaddedContainer>
		</PaddedContainer>
	);
});

export const FacebookAudienceStatusCards = memo(
	({ hasRequestedAudiences = false, audiencesRequests = false }) => {
		return (
			<PaddedContainer
				marginTop={SPACING.MEDIUM}
				className="facebook-audience-status-cards"
			>
				{hasRequestedAudiences &&
					Array.isArray(audiencesRequests?.lookalike_requests) &&
					audiencesRequests?.lookalike_requests.length > 0 &&
					audiencesRequests.lookalike_requests.map(
						(lookalikeRequest) => (
							<>
								<FacebookAudienceStatusCard
									key={lookalikeRequest?.remote_id || ""}
									audienceInfo={lookalikeRequest}
								/>
							</>
						)
					)}
			</PaddedContainer>
		);
	}
);
export const FacebookAudienceRequester = memo(
	({
		user,
		audiencesMeta,
		audiencesRequests,
		setAudiencesRequests,
		navProps,
	}) => {
		const [requestParams, setRequestParams] = useState({
			audience_count: REQUESTABLE_AUDIENCE_SIZE.DEFAULT,
		});

		const { backLoading, handleBack } = useMemo(
			() => navProps || {},
			[navProps]
		);

		const [
			{
				data: newRequestData,
				error: newRequestError,
				loading: newRequestLoading,
			},
			requestNewAudience,
		] = useResource(
			{
				url: REQUEST_LOOKALIKE_AUDIENCE_URL,
				method: "POST",
				data: requestParams,
			},
			false
		);
		const { canRequestAudiences, hasRequestedAudiences, accountId } =
			useMemo(() => audiencesMeta, [audiencesMeta]);

		const handleRequestSizeChange = useCallback((audience_count) => {
			setRequestParams((requestParams) => ({
				...requestParams,
				audience_count,
			}));
		}, []);

		const handleRequestInit = useCallback(() => {
			if (!canRequestAudiences) {
				return;
			}
			setRequestParams((requestParams) => ({
				...requestParams,
				account_id: accountId,
			}));
			requestNewAudience();
		}, [canRequestAudiences, accountId, requestNewAudience]);

		useEffect(() => {
			if (!newRequestData || typeof setAudiencesRequests !== "function") {
				return;
			}
			setAudiencesRequests((audiencesRequests) => ({
				...audiencesRequests,
				lookalike_requests: [
					newRequestData.lookalike_request,
					...audiencesRequests?.lookalike_requests,
				],
			}));
		}, [newRequestData, setAudiencesRequests]);

		return (
			<PaddedContainer
				motionElement
				marginTop={SPACING.MEDIUM}
				marginBottom={SPACING.LARGE}
				className={genClassName({
					base: "facebook-audience-requestor",
					conditionals: {
						"facebook-audience-requestor-warning":
							!canRequestAudiences,
					},
				})}
				variants={anim.variantFactory({
					y: 30,
					opacity: 0,
				})}
			>
				{(!hasRequestedAudiences || user.isSuper) && (
					<>
						<Label>Request an audience</Label>
						<Slider
							min={REQUESTABLE_AUDIENCE_SIZE.MIN}
							max={REQUESTABLE_AUDIENCE_SIZE.MAX}
							step={REQUESTABLE_AUDIENCE_SIZE.STEP}
							value={requestParams.audience_count}
							disabled={newRequestLoading || !canRequestAudiences}
							onChange={handleRequestSizeChange}
						/>
						<PaddedContainer
							marginTop={SPACING.LARGE}
							className={genClassName({
								base: "facebook-audience-requestor-slider-status",
								conditionals: {
									"facebook-audience-requestor-slider-status-warning":
										!canRequestAudiences,
								},
							})}
						>
							<StatusLabel
								hPadding={SPACING.TINY}
								vPadding={SPACING.TINY}
							>
								{canRequestAudiences ? (
									<>
										I would like an audience size of —{" "}
										<b>
											{readableInt(
												requestParams.audience_count
											)}
										</b>
									</>
								) : (
									"Please select an ad account first to request an audience"
								)}
							</StatusLabel>
						</PaddedContainer>
					</>
				)}

				{newRequestError && (
					<StatusLabel>
						{parseError(newRequestError, true)}
					</StatusLabel>
				)}
				<PaddedContainer className="audiences-match-step-card-btn-container">
					{backLoading ? (
						<Loader />
					) : (
						<Button onClick={handleBack} icon={<ChevronLeftIcon />}>
							Back
						</Button>
					)}
					{audiencesRequests &&
						(!audiencesMeta.hasRequestedAudiences ||
							user.isSuper) && (
							<>
								{newRequestLoading ? (
									<Loader />
								) : (
									canRequestAudiences && (
										<Button
											onClick={handleRequestInit}
											disabled={!canRequestAudiences}
										>
											Request Audience
										</Button>
									)
								)}
							</>
						)}
				</PaddedContainer>
			</PaddedContainer>
		);
	}
);

export const FacebookDisconnectButton = memo(
	({ setUser, onDisconnect, onError, hideInternalError = false }) => {
		const [
			{
				data: audienceLogoutData,
				loading: audienceLogoutLoading,
				error: audienceLogoutError,
			},
			triggerAudienceLogout,
		] = useResource(
			{
				method: "PUT",
				url: FACEBOOK_LOG_OUT_URL,
			},
			false
		);

		useEffect(() => {
			if (!audienceLogoutData) {
				return;
			}
			setUser((user) => ({
				...user,
				audience_setup_status:
					audienceLogoutData?.audience_setup_status || 3,
				facebook_access_token: null,
			}));
			if (onDisconnect && typeof onDisconnect === "function") {
				onDisconnect();
			}
		}, [audienceLogoutData, setUser, onDisconnect]);

		useEffect(() => {
			if (
				!audienceLogoutError ||
				!onError ||
				typeof onError !== "function"
			) {
				return;
			}
			onError();
		}, [audienceLogoutError, onError]);

		return (
			<>
				{audienceLogoutLoading ? (
					<Loader />
				) : (
					<Button
						className="facebook-disconnect-btn"
						onClick={triggerAudienceLogout}
					>
						Disconnect Facebook Business Manager
					</Button>
				)}
				{!hideInternalError && audienceLogoutError && (
					<StatusLabel>
						Whoops! We couldn't disconnect your account at the
						moment.
						<br />
						Please try again later.
					</StatusLabel>
				)}
			</>
		);
	}
);
