import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
	PaddedContainer,
	Button,
	MoreHorizontalIcon,
	FONT_COLOR,
	SPACING,
	Asset,
	Label,
	LabeledInput,
	Text,
	Card,
	FONT_SIZE,
	StatusLabel,
	STATUS,
	Loader,
	DropdownSelect,
	parseError,
} from "@disco/disco_core";

import WidgetModule, { WidgetModuleToggler } from "./WidgetModule";

import {
	PREVIEW_TYPES_IDX,
	SAVE_LEADGEN_URL,
	SAVE_LEAD_OFFERS_URL,
	FETCH_LEAD_OFFERS_URL,
} from "../../conf";

import { PLATFORM_LEAD_OFFERS_TOGGLE } from "../../events";
import useIdentity from "../../hooks/useIdentity";
import LeadOffersKlaviyoConfig from "../LeadOffersKlaviyoConfig";

import "./lead-offers-module.css";
import useResource from "../../hooks/useResource";

const LeadOfferModuleSettings = memo(({ user, setUser, triggerRefresh }) => {
	const label = useMemo(
		() => <Label color={FONT_COLOR.MID}>Enable Nurture Offer</Label>,
		[]
	); // Manual Override to sync with design system
	return (
		<>
			<WidgetModuleToggler
				user={user}
				setUser={setUser}
				label={label}
				remoteKey="is_active_lead_gen"
				toggleUrl={SAVE_LEADGEN_URL}
				onToggle={triggerRefresh}
				event={PLATFORM_LEAD_OFFERS_TOGGLE}
			/>
			<LeadOffersKlaviyoConfig />
			<ConfigureLeadOffersPanel />
		</>
	);
});

const ConfigureLeadOffersPanel = memo(() => {
	const [formErrors, setFormErrors] = useState({});
	const [editOffer, setEditOffer] = useState(false);
	const [offer, setOffer] = useState(null);

	const validOffer = useCallback(() => {
		setFormErrors({});

		let errors = {};

		if (!offer?.code) {
			errors.code = "Please enter an Offer Code";
		}

		if (!offer?.heading) {
			errors.heading = "Please enter an Offer Heading";
		}

		if (errors?.code || errors?.heading) {
			setFormErrors(errors);
			return false;
		}

		return true;
	}, [offer]);

	const [
		{
			loading: getLeadOffersLoading,
			data: getLeadOffersData,
			error: getLeadOffersError,
		},
		,
		resetGetLeadOffers,
	] = useResource({
		url: FETCH_LEAD_OFFERS_URL,
		method: "GET",
	});

	const [
		{
			loading: saveLeadOffersLoading,
			data: saveLeadOffersData,
			error: saveLeadOffersError,
		},
		saveLeadOffers,
		resetSaveLeadOffers,
	] = useResource(
		{
			url: offer?.remote_id
				? SAVE_LEAD_OFFERS_URL + offer?.remote_id + "/"
				: SAVE_LEAD_OFFERS_URL,
			method: offer?.remote_id ? "PUT" : "POST",
			data: {
				code: offer?.code || null,
				heading: offer?.heading || null,
				subheading: offer?.subheading || null,
			},
		},
		false
	);

	const [
		{
			loading: deleteLeadOfferLoading,
			data: deleteLeadOfferData,
			error: deleteLeadOfferError,
		},
		deleteLeadOffer,
		resetDeleteLeadOffer,
	] = useResource(
		{
			url: SAVE_LEAD_OFFERS_URL + offer?.remote_id + "/",
			method: "DELETE",
			data: null,
		},
		false
	);

	useEffect(() => {
		if (!getLeadOffersData) return;

		if (getLeadOffersData?.offers[0]) {
			setOffer(getLeadOffersData?.offers[0]);
		}

		resetGetLeadOffers();
	}, [getLeadOffersData, resetGetLeadOffers]);

	useEffect(() => {
		if (!saveLeadOffersData) return;

		if (saveLeadOffersData?.offer) {
			setOffer(saveLeadOffersData?.offer);
			setEditOffer(false);
		}

		resetSaveLeadOffers();
	}, [saveLeadOffersData, resetSaveLeadOffers]);

	useEffect(() => {
		if (!deleteLeadOfferData) return;

		setOffer(null);

		resetDeleteLeadOffer();
	}, [deleteLeadOfferData, resetDeleteLeadOffer]);

	const handleNewOffer = useCallback(() => {
		setEditOffer(true);
	}, []);

	const handleOfferSaved = useCallback(() => {
		if (!validOffer()) {
			return;
		}

		saveLeadOffers();
	}, [validOffer, saveLeadOffers]);

	return (
		<PaddedContainer marginTop={SPACING.REGULAR}>
			<Label uppercase color={FONT_COLOR.MID}>
				{(!offer || editOffer) && "Configure"} Nurture Offer
			</Label>

			{getLeadOffersLoading ||
			saveLeadOffersLoading ||
			deleteLeadOfferLoading ? (
				<Loader middle />
			) : offer && !editOffer ? (
				<LeadOffer
					offer={offer}
					setEditOffer={setEditOffer}
					deleteLeadOffer={deleteLeadOffer}
				/>
			) : (
				<LeadOfferConfiguration
					offer={offer}
					setOffer={setOffer}
					editOffer={editOffer}
					handleNewOffer={handleNewOffer}
					handleOfferSaved={handleOfferSaved}
					formErrors={formErrors}
				/>
			)}

			{(getLeadOffersError ||
				saveLeadOffersError ||
				deleteLeadOfferError) && (
				<StatusLabel type={STATUS.ERROR} marginTop={SPACING.TINY}>
					{getLeadOffersError
						? parseError(getLeadOffersError)
						: saveLeadOffersError
						? parseError(saveLeadOffersError)
						: parseError(deleteLeadOfferError)}
				</StatusLabel>
			)}
		</PaddedContainer>
	);
});

const LeadOfferConfiguration = memo(
	({
		offer,
		setOffer,
		editOffer,
		handleNewOffer,
		handleOfferSaved,
		formErrors,
	}) => {
		const handleOfferChange = useCallback(
			({ target: { name, value } }) => {
				setOffer((offer) => ({ ...offer, [name]: value }));
			},
			[setOffer]
		);

		const preventEnter = useCallback((e) => {
			if (e.key === "Enter") e.preventDefault();
		}, []);

		const leadOfferInputProps = {
			onChange: handleOfferChange,
			textarea: true,
			type: "text",
			className: "lead-offers-module",

			onKeyPress: preventEnter,
		};

		const leadOfferInputs = [
			{
				label: "OFFER CODE",
				name: "code",
				placeholder: "SHOP30DISCO",
				value: offer?.code,
				maxLength: 25,
				labelProps: {
					required: true,
					color: FONT_COLOR.MID,
					marginTop: SPACING.REGULAR,
				},
				children: (
					<StatusLabel
						marginTop={8}
						vPadding={SPACING.SMALL}
						hPadding={SPACING.TINY}
						noBorder
						noShadow
						type={STATUS.INFO}
						childrenProps={{
							thin: true,
						}}
					>
						<Text>
							Please ensure the Customer Eligibility is set to{" "}
							<b>All Customers</b> in the offer code settings in
							Shopify.
						</Text>
					</StatusLabel>
				),
				...leadOfferInputProps,
			},
			{
				label: "OFFER HEADING",
				name: "heading",
				placeholder: "30% off",
				value: offer?.heading,
				maxLength: 20,
				labelProps: {
					required: true,
					color: FONT_COLOR.MID,
					marginTop: SPACING.REGULAR,
				},
				...leadOfferInputProps,
			},
			{
				label: "OFFER SUBHEADING",
				name: "subheading",
				placeholder: "On your entire purchase",
				value: offer?.subheading,
				maxLength: 30,
				labelProps: {
					color: FONT_COLOR.MID,
					marginTop: SPACING.REGULAR,
				},
				...leadOfferInputProps,
			},
		];

		return !offer && !editOffer ? (
			<Button
				onClick={handleNewOffer}
				rounded={false}
				marginTop={SPACING.TINY}
			>
				New Offer
			</Button>
		) : (
			<>
				<PaddedContainer
					className="lead-offers-module-separator"
					marginTop={SPACING.SMALL}
					marginBottom={SPACING.MEDIUM}
				/>
				<PaddedContainer className="lead-offers-configuration">
					{leadOfferInputs.map((props) => {
						return (
							<LabeledInput key={props.name} {...props}>
								{props?.children || null}
								{formErrors[props.name] && (
									<StatusLabel
										type={STATUS.ERROR}
										marginTop={SPACING.TINY}
									>
										{formErrors[props.name]}
									</StatusLabel>
								)}
							</LabeledInput>
						);
					})}
					<Button
						onClick={handleOfferSaved}
						rounded={false}
						marginTop={SPACING.MEDIUM}
					>
						Save Offer
					</Button>
				</PaddedContainer>
			</>
		);
	}
);

const LeadOffer = memo(({ offer, setEditOffer, deleteLeadOffer }) => {
	const handleEdit = useCallback(() => {
		setEditOffer(true);
	}, [setEditOffer]);

	const handleDelete = useCallback(() => {
		deleteLeadOffer();
	}, [deleteLeadOffer]);

	const options = useMemo(
		() => ({
			deleteOffer: {},
		}),
		[]
	);

	const renderOptions = useCallback(() => {
		return (
			<Text
				color={"var(--disco-red-mid)"}
				thick
				centerContent
				size={FONT_SIZE.BODY}
				clickable
				onClick={handleDelete}
			>
				Delete Offer
			</Text>
		);
	}, [handleDelete]);

	return (
		<Card
			marginTop={SPACING.TINY}
			vPadding={SPACING.MEDIUM}
			hPadding={SPACING.REGULAR}
			contentProps={{ flexContent: true }}
		>
			<PaddedContainer flexContent={false}>
				<Text thick size={FONT_SIZE.LG_BODY}>
					{offer?.heading}
				</Text>
				<Text size={FONT_SIZE.LG_BODY}>{offer?.code}</Text>
			</PaddedContainer>

			<PaddedContainer className="lead-offer-edit-container">
				<Text
					size={FONT_SIZE.LG_BODY}
					color={"var(--theme)"}
					clickable
					alignContentCenter
					onClick={handleEdit}
					marginTop={SPACING.SMALL}
				>
					Edit
				</Text>
				<DropdownSelect
					value=""
					placeholder=""
					hideToggleIcon
					className="lead-offers-select"
					activator={
						<PaddedContainer>
							<Asset size={20}>
								<MoreHorizontalIcon />
							</Asset>
						</PaddedContainer>
					}
					options={options}
					renderCustomOptions={renderOptions}
					multiple={false}
					searchable={false}
				/>
			</PaddedContainer>
		</Card>
	);
});

const LeadOffersModule = memo(() => {
	const { user, setUser } = useIdentity();

	const renderSettings = useCallback(
		(props) => (
			<LeadOfferModuleSettings user={user} setUser={setUser} {...props} />
		),
		[user, setUser]
	);

	const tabOptions = useMemo(() => {
		let options = [
			{
				component: renderSettings,
				disablePreview: true,
			},
		];
		return options;
	}, [renderSettings]);

	return (
		<>
			<section className="lead-gen-module-wrapper">
				<WidgetModule
					user={user}
					setUser={setUser}
					tabOptions={tabOptions}
					heading={"Nurture"}
					className="lead-gen-module"
				/>
			</section>
		</>
	);
});

export default LeadOffersModule;
