export const SNOWPLOW_ENVIRONMENT = {
	PRODUCTION: "PRODUCTION",
	DEVELOPMENT: "DEVELOPMENT",
};
export const SNOWPLOW_EVENT_TYPE = {
	SELF_DESCRIBING: "trackSelfDescribingEvent",
	SOCIAL_INTERACTION: "trackSocialInteraction",
};
export const SNOWPLOW_ENTITY = {
	DISPLAY_POSITION: "DISPLAY_POSITION",
	BRAND: "BRAND",
	PUBLISHER: "PUBLISHER",
	PRODUCT: "PRODUCT",
	PURCHASE: "PURCHASE",
	PLATFORM: "PLATFORM",
	SESSION_CONFIGURATION: "SESSION_CONFIGURATION",
	ORDER: "ORDER",
	DISCO_DIMENSIONS: "DISCO_DIMENSIONS",
	PROMOTED_IDENTIFICATION: "PROMOTED_IDENTIFICATION",
};

export const SNOWPLOW_ENTITY_SCHEMA_VERSION = {
	[SNOWPLOW_ENTITY.DISPLAY_POSITION]: {
		[SNOWPLOW_ENVIRONMENT.DEVELOPMENT]: "1-0-1",
		[SNOWPLOW_ENVIRONMENT.PRODUCTION]: "1-0-1",
	},
	[SNOWPLOW_ENTITY.BRAND]: {
		[SNOWPLOW_ENVIRONMENT.DEVELOPMENT]: "1-0-0",
		[SNOWPLOW_ENVIRONMENT.PRODUCTION]: "1-0-0",
	},
	[SNOWPLOW_ENTITY.PUBLISHER]: {
		[SNOWPLOW_ENVIRONMENT.DEVELOPMENT]: "1-0-0",
		[SNOWPLOW_ENVIRONMENT.PRODUCTION]: "1-0-0",
	},
	[SNOWPLOW_ENTITY.PRODUCT]: {
		[SNOWPLOW_ENVIRONMENT.DEVELOPMENT]: "2-0-0",
		[SNOWPLOW_ENVIRONMENT.PRODUCTION]: "2-0-0",
	},
	[SNOWPLOW_ENTITY.PURCHASE]: {
		[SNOWPLOW_ENVIRONMENT.DEVELOPMENT]: "1-0-5",
		[SNOWPLOW_ENVIRONMENT.PRODUCTION]: "1-0-5",
	},
	[SNOWPLOW_ENTITY.PLATFORM]: {
		[SNOWPLOW_ENVIRONMENT.DEVELOPMENT]: "1-0-0",
		[SNOWPLOW_ENVIRONMENT.PRODUCTION]: "1-0-0",
	},
	[SNOWPLOW_ENTITY.SESSION_CONFIGURATION]: {
		[SNOWPLOW_ENVIRONMENT.DEVELOPMENT]: "2-0-7",
		[SNOWPLOW_ENVIRONMENT.PRODUCTION]: "2-0-7",
	},
	[SNOWPLOW_ENTITY.ORDER]: {
		[SNOWPLOW_ENVIRONMENT.DEVELOPMENT]: "1-0-3",
		[SNOWPLOW_ENVIRONMENT.PRODUCTION]: "1-0-3",
	},
	[SNOWPLOW_ENTITY.DISCO_DIMENSIONS]: {
		[SNOWPLOW_ENVIRONMENT.DEVELOPMENT]: "1-0-3",
		[SNOWPLOW_ENVIRONMENT.PRODUCTION]: "1-0-3",
	},
	[SNOWPLOW_ENTITY.PROMOTED_IDENTIFICATION]: {
		[SNOWPLOW_ENVIRONMENT.DEVELOPMENT]: "1-0-1",
		[SNOWPLOW_ENVIRONMENT.PRODUCTION]: "1-0-1",
	},
};

export const SNOWPLOW_ENTITY_SCHEMA = ({ version = "" } = {}) => ({
	[SNOWPLOW_ENTITY.DISPLAY_POSITION]: `iglu:com.disconetwork/display_position/jsonschema/${version}`,
	[SNOWPLOW_ENTITY.BRAND]: `iglu:com.disconetwork/brand/jsonschema/${version}`,
	[SNOWPLOW_ENTITY.PUBLISHER]: `iglu:com.disconetwork/publisher/jsonschema/${version}`,
	[SNOWPLOW_ENTITY.PRODUCT]: `iglu:com.disconetwork/product/jsonschema/${version}`,
	[SNOWPLOW_ENTITY.PURCHASE]: `iglu:com.disconetwork/purchase/jsonschema/${version}`,
	[SNOWPLOW_ENTITY.PLATFORM]: `iglu:com.disconetwork/platform/jsonschema/${version}`,
	[SNOWPLOW_ENTITY.SESSION_CONFIGURATION]: `iglu:com.disconetwork/session_configuration/jsonschema/${version}`,
	[SNOWPLOW_ENTITY.ORDER]: `iglu:com.disconetwork/order/jsonschema/${version}`,
	[SNOWPLOW_ENTITY.DISCO_DIMENSIONS]: `iglu:com.disconetwork/disco_dimensions/jsonschema/${version}`,
	[SNOWPLOW_ENTITY.PROMOTED_IDENTIFICATION]: `iglu:com.disconetwork/promoted_identification/jsonschema/${version}`,
});

export const SNOWPLOW_ENTITY_DATA = {
	[SNOWPLOW_ENTITY.DISPLAY_POSITION]: [
		"verticalPosition",
		"horizontalPosition",
	],
	[SNOWPLOW_ENTITY.BRAND]: ["id", "name"],
	[SNOWPLOW_ENTITY.PUBLISHER]: ["id", "name"],
	[SNOWPLOW_ENTITY.PRODUCT]: ["id", "name"],
	[SNOWPLOW_ENTITY.PURCHASE]: ["products"],
	[SNOWPLOW_ENTITY.PLATFORM]: ["code", "shopUrl"],
	[SNOWPLOW_ENTITY.SESSION_CONFIGURATION]: [
		"runningExperiments",
		"upsell",
		"survey",
		"totalModules",
		"crossSellAlgorithmType",
		"crossSellAlgorithmVersion",
		"recommenderMechanism",
		"mlModel",
		"cacheSize",
		"widgetVersion",
		"widgetType",
	],
	[SNOWPLOW_ENTITY.ORDER]: ["orderId", "remoteId", "cost", "scrapedCost"],
	[SNOWPLOW_ENTITY.DISCO_DIMENSIONS]: [
		"frameHeight",
		"frameWidth",
		"windowHeight",
		"windowWidth",
	],
	[SNOWPLOW_ENTITY.PROMOTED_IDENTIFICATION]: [
		"userId",
		"insertionId",
		"isAnonymous",
	],
};

export const SNOWPLOW_ENTITY_ENUM = {
	// entities
	[SNOWPLOW_ENTITY.PLATFORM]: {
		// entity properties
		CODE: {
			// entity property enums
			SHOPIFY: "shopify",
		},
	},
};
