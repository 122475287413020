import React, { memo, useMemo } from "react";
import "./audiences-banner.css";

import {
	Asset,
	Button,
	FONT_SIZE,
	PaddedContainer,
	SPACING,
	Text,
} from "@disco/disco_core";
import useContent from "../../hooks/useContent";
import cartIcon from "../../img/audiences/cart-icon.png";
import phoneIcon from "../../img/audiences/phone-icon.png";
import settingsIcon from "../../img/audiences/settings-icon.png";
import triangle from "../../img/audiences/triangle.svg";
import { AUDIENCES_TABS } from "../feedModules/AudiencesModule";

const AudiencesBanner = memo(({ changeTab }) => {
	const content = useContent().platformAudiences;
	const footerFeatures = useMemo(
		() => [
			{
				heading: content.sequenceHeading1,
				text: content.sequenceText1,
				icon: settingsIcon,
			},
			{
				heading: content.sequenceHeading2,
				text: content.sequenceText2,
				icon: phoneIcon,
			},
			{
				heading: content.sequenceHeading3,
				text: content.sequenceText3,
				icon: cartIcon,
			},
		],
		[content]
	);

	return (
		<PaddedContainer
			data-testid={`audiences-banner`}
			className="audiences-banner"
			vPadding={SPACING.JUMBO}
			tightBottom
			marginTop={SPACING.MEDIUM}
		>
			<PaddedContainer Element="main" hPadding={SPACING.LARGE}>
				<Text size={FONT_SIZE.SUB_TITLE} thick>
					{content.bannerHeading}
				</Text>
				<Text
					marginTop={SPACING.SMALL}
					size={FONT_SIZE.LG_BODY}
					thin
					className="audiences-banner-text"
				>
					{content.bannerText}
				</Text>
				<Button
					rounded
					marginTop={SPACING.MEDIUM}
					className="audiences-banner-btn disco-shop"
					onClick={() => changeTab(AUDIENCES_TABS.INFO)}
				>
					{content.bannerCta}
				</Button>
			</PaddedContainer>
			<PaddedContainer Element="footer" marginTop={SPACING.JUMBO}>
				{footerFeatures.map(({ icon, text, heading }, index) => (
					<>
						<PaddedContainer
							className="audiences-banner-footer-feature"
							vPadding={SPACING.LARGE}
						>
							<Asset hPadding={SPACING.MEDIUM}>
								<img src={icon} alt="" />
							</Asset>
							<PaddedContainer Element="main">
								<Text
									Element="h3"
									thick
									size={FONT_SIZE.LG_BODY}
								>
									{heading}
								</Text>
								<Text
									thin
									size={FONT_SIZE.BODY}
									marginTop={SPACING.TINY}
								>
									{text}
								</Text>
							</PaddedContainer>
						</PaddedContainer>
						{index < footerFeatures.length - 1 && (
							<img
								className="audiences-banner-footer-feature-sep"
								src={triangle}
								alt=""
							/>
						)}
					</>
				))}
			</PaddedContainer>
		</PaddedContainer>
	);
});

export default AudiencesBanner;
