import React, { memo, useMemo } from "react";
import TopLevelPage from "../TopLevelPage";
import {
	CrossSellModule,
	UpsellModule,
	LeadOffersModule,
} from "../../components/feedModules";
import Products from "../../components/Products";
import useIdentity from "../../hooks/useIdentity";

const subPages = {
	discoFeed: CrossSellModule,
	products: Products,
	upsells: UpsellModule,
	nurture: LeadOffersModule,
};

const DiscoFeedPage = memo(() => {
	return <TopLevelPage subPages={subPages}></TopLevelPage>;
});

export default DiscoFeedPage;
