import { Input, PaddedContainer } from "@disco/disco_core";
import React, { forwardRef, memo, useCallback } from "react";

import "./audiences-name.css";

export const AudiencesName = memo(
	forwardRef(({ reqName, setReqName, ...rest }, ref) => {
		const handleChange = useCallback(
			({ target: { value } }) => {
				setReqName(value);
			},
			[setReqName]
		);

		return (
			<>
				<PaddedContainer className="audiences-name">
					<Input
						value={reqName}
						onChange={handleChange}
						placeholder={`Audience Name`}
						ref={ref}
						{...rest}
					/>
				</PaddedContainer>
			</>
		);
	})
);

export default AudiencesName;
