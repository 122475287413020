import React, { memo, useEffect, useMemo } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { BorderedCard, Button, Loader } from "../../components/CoreUI";
import { AUDIENCES_PROVIDERS, AUDIENCES_PROVIDER_DATA } from "../../conf";
import { PLATFORM_AUDIENCES_CONNECT_INTEGRATION_ACCOUNT_SUCCESS } from "../../events";
import useIdentity from "../../hooks/useIdentity";
import useResource from "../../hooks/useResource";
import useTrack from "../../hooks/useTrack";
import { GET } from "../../utils/GET";
import anim from "../../utils/anim";
import getPlatformPath from "../../utils/getPlatformPath";
import "./audiences-connect-page.css";

const AudiencesConnectPage = memo(() => {
	const { user, setUser } = useIdentity();

	let { provider } = useParams();
	provider = Number(provider);

	const payload = useMemo(() => {
		const params = new URLSearchParams(
			`?${window.location.hash.substring(1).trim()}`
		);

		return provider === AUDIENCES_PROVIDERS.SNAP
			? { code: GET("code") }
			: provider === AUDIENCES_PROVIDERS.TIKTOK
			? { auth_code: GET("auth_code") }
			: { access_token: params.get("access_token") };
	}, [provider]);

	const [{ data, error }, , reset] = useResource({
		url: AUDIENCES_PROVIDER_DATA[provider]?.oauthUrl,
		method: "POST",
		data: payload,
	});

	const navigate = useNavigate();

	const track = useTrack();

	useEffect(() => {
		if (!data) return;

		setUser((user) => ({
			...user,
			publisher: {
				...user.publisher,
				[AUDIENCES_PROVIDER_DATA[provider]?.connectionKey]: true,
			},
		}));

		track(PLATFORM_AUDIENCES_CONNECT_INTEGRATION_ACCOUNT_SUCCESS, {
			integration: AUDIENCES_PROVIDER_DATA[provider]?.name,
		});

		reset();
		navigate(getPlatformPath("audiences", user, { provider }));
	}, [data, setUser, navigate, user, reset, provider, track]);

	return error ? (
		<BorderedCard
			heading="Something went wrong 😢"
			subHeading={`We could not connect your ${AUDIENCES_PROVIDER_DATA[provider]?.name} account. You can try again later.`}
			centered
			className="audiences-connect-page-card"
			narrow={false}
			variants={anim.dialog}
			initial="initial"
			animate="animate"
			exit="exit"
			footer={
				<>
					<Button
						onClick={() =>
							navigate(
								getPlatformPath("audiences", user, { provider })
							)
						}
						large
						rounded
						gradient
					>
						Back to audiences
					</Button>
				</>
			}
		></BorderedCard>
	) : AUDIENCES_PROVIDER_DATA[provider] ? (
		<Loader center light />
	) : (
		<Navigate to={getPlatformPath("audiences", user)} />
	);
});

export default AudiencesConnectPage;
