import React, { memo } from "react";
import useContent from "../../hooks/useContent";
import AudiencesOverviewModule from "../AudiencesOverviewModule";
import "./audiences-history.css";
import AudiencesHistoryTableWrapper from "./AudiencesHistioryTableWrapper";

export const AudiencesHistoryOverview = memo(() => {
	const content = useContent().platformAudiences;
	return (
		<AudiencesOverviewModule
			heading="Audience History"
			subHeading={content.historyText}
			data-testid="audiences-history-overview"
		>
			<AudiencesHistoryTableWrapper />
		</AudiencesOverviewModule>
	);
});

export const AudiencesHistoryTab = memo(() => {
	return (
		<AudiencesHistoryTableWrapper>
			Audiences History
		</AudiencesHistoryTableWrapper>
	);
});
