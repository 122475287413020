import { useCallback, useEffect, useState } from "react";
import parseDate from "../utils/parseDate";

export const getTimeDelta = (
	updateTime,
	maxDeltaDays = Infinity,
	append = ""
) => {
	const epoch = new Date(updateTime).getTime();

	if (isNaN(epoch)) {
		return `0m${append}`;
	}
	const delta = Math.round((new Date() - epoch) / 1000);

	if (delta > maxDeltaDays * 3600 * 24)
		return parseDate({ date: updateTime });

	if (delta <= 60) {
		return `${delta}s`;
	} else if (delta < 3600) {
		return `${Math.round(delta / 60)}m${append}`;
	} else if (delta < 24 * 3600) {
		return `${Math.round(delta / 3600)}h${append}`;
	} else if (delta < 7 * 24 * 3600) {
		return `${Math.round(delta / 3600 / 24)}d${append}`;
	} else if (delta < 30 * 24 * 3600) {
		return `${Math.round(delta / 3600 / 24 / 7)}w${append}`;
	} else if (delta < 365 * 24 * 3600) {
		return `${Math.round(delta / 3600 / 24 / 30)}mo${append}`;
	} else {
		return `${Math.round(delta / 3600 / 24 / 365)}y${append}`;
	}
};

const useUpdatedAt = (updateTime, pattern = "$TIME", refresh = 60000) => {
	const getUpdatedAt = useCallback(
		() => pattern.replace("$TIME", getTimeDelta(updateTime)),
		[updateTime, pattern]
	);

	const [updatedAt, setUpdatedAt] = useState("");

	useEffect(() => {
		setUpdatedAt(getUpdatedAt);
		const timer = setInterval(() => setUpdatedAt(getUpdatedAt), refresh);
		return () => clearInterval(timer);
	}, [getUpdatedAt, refresh]);

	if (updateTime === undefined || updateTime === null) {
		return null;
	}

	return updatedAt;
};

export default useUpdatedAt;
