import React from "react";
import RouteRenderer from "../components/RouteRenderer";
import Tracker from "../components/Tracker";
import AudiencesConnectPage from "../pages/AudiencesConnectPage";
import AudiencesPage from "../pages/AudiencesPage";
import BigCommerceLoginPage from "../pages/BigCommerceLoginPage";
import DiscoFeedPage from "../pages/DiscoFeedPage";
import EmailsPage from "../pages/EmailsPage";
import JoinTeamPage from "../pages/JoinTeamPage";
import LoginTeamPage from "../pages/LoginTeamPage";
import MoreToolsPage from "../pages/MoreToolsPage";
import OAuthEmbedPage from "../pages/OAuthEmbedPage";
import OnboardingPage from "../pages/OnboardingPage";
import PerformancePage from "../pages/PerformancePage";
import ProcessingPage from "../pages/ProcessingPage";
import ProductsPage from "../pages/ProductsPage";
import RegisterTeamPage from "../pages/RegisterTeamPage";
import RootPage from "../pages/RootPage";
import SettingsPage from "../pages/SettingsPage";
import ShopifyConnectPage from "../pages/ShopifyConnectPage";
import ShopifyLoginPage from "../pages/ShopifyLoginPage";
import ShopifyPage from "../pages/ShopifyPage";
import LoginPage from "../pages/formPages/LoginPage";
import PasswordResetConfirmationPage from "../pages/formPages/PasswordResetConfirmationPage";
import PasswordResetPage from "../pages/formPages/PasswordResetPage";
import RegisterPage from "../pages/formPages/RegisterPage";
import SuperLoginPage from "../pages/formPages/SuperLoginPage";
import { Route } from "react-router-dom";

const routesData = [
	{
		path: "/",
		page: RootPage,
		props: { loggedIn: true, loggedOut: true, exact: true },
	},
	{
		path: "/oauthEmbed",
		page: OAuthEmbedPage,
		props: { loggedIn: true, loggedOut: true, setupPage: true },
	},
	{
		path: "/login",
		page: LoginPage,
		props: { loggedOut: true },
	},
	{ path: "/reset", page: PasswordResetPage, props: { loggedOut: true } },
	{
		path: "/resetConfirmation",
		page: PasswordResetConfirmationPage,
		props: { loggedOut: true },
	},
	{ path: "/super", page: SuperLoginPage, props: { loggedOut: true } },
	{ path: "/register", page: RegisterPage, props: { loggedOut: true } },

	{
		path: "/joinTeam",
		page: JoinTeamPage,
		props: { loggedIn: true, logoutRedirect: "/registerTeam" },
	},
	{ path: "/loginTeam", page: LoginTeamPage, props: { loggedOut: true } },
	{
		path: "/registerTeam",
		page: RegisterTeamPage,
		props: { resetLogin: true, loggedOut: true },
	},
	{
		path: "/shopifyConnect",
		page: ShopifyConnectPage,
		props: { resetLogin: true },
	},
	{
		path: "/onboarding",
		page: OnboardingPage,
		props: { setupPage: true, loggedIn: true },
	},
	{
		path: "/shopifyLogin",
		page: ShopifyLoginPage,
		props: { resetLogin: true },
	},
	{ path: "/processing", page: ProcessingPage, props: { loggedIn: true } },
	{ path: "/shopify", page: ShopifyPage, props: { loggedIn: true } },

	{
		path: "/audiencesConnect/:provider?",
		page: AudiencesConnectPage,
		props: { loggedIn: true },
	},
	// Sidebar pages
	{
		path: "/bigcommerceLogin",
		page: BigCommerceLoginPage,
		props: { resetLogin: true },
	},
	{
		path: "/manage/:subPage?",
		page: DiscoFeedPage,
		props: { loggedIn: true },
	},
	{
		path: "/performance/:subPage?",
		page: PerformancePage,
		props: { loggedIn: true },
	},
	{
		path: "/moreTools/:subPage?",
		page: MoreToolsPage,
		props: { loggedIn: true },
	},

	{
		path: "/settings",
		page: SettingsPage,
		props: { loggedIn: true, exact: false },
	},
	{ path: "/emails", page: EmailsPage, props: { loggedIn: true } },
	{ path: "/products", page: ProductsPage, props: { loggedIn: true } },
	{ path: "/audiences", page: AudiencesPage, props: { loggedIn: true } },
];

const makeRoutes = (mock) =>
	routesData.map((route) => {
		const {
			path,
			props: {
				exact,
				setupPage,
				loggedIn,
				loggedOut,
				resetLogin,
				logoutRedirect,
			} = {},
		} = route;

		const render = (props) => {
			const page = <route.page {...props} />;
			return route.props?.loggedIn ? (
				<Tracker user={props.user}>{page}</Tracker>
			) : (
				<Tracker>{page}</Tracker>
			);
		};

		return (
			<Route
				key={route.path}
				path={`${path}${!exact ? "/*" : ""}`}
				element={
					<RouteRenderer
						key={route.path}
						render={render}
						path={path}
						setupPage={setupPage}
						loggedIn={loggedIn}
						loggedOut={loggedOut}
						resetLogin={resetLogin}
						logoutRedirect={logoutRedirect}
						mock={mock}
					/>
				}
			/>
		);
	});

export default makeRoutes;
