import React, { forwardRef, memo, useCallback } from "react";
import { BANNER_IMAGE_UPLOAD_URL, PROFILE_IMAGE_UPLOAD_URL } from "../../conf";
import { EditImage, BadgeImageInput } from "../CoreUI";
import "./brand-images.css";

const BrandImages = memo(
	forwardRef(({ user, setUser, onChange, ...rest }, ref) => {
		const bannerSelector = useCallback(
			(user) => user.publisher.banner_url,
			[]
		);
		const logoSelector = useCallback((user) => user.publisher.logo_url, []);

		const renderLogo = useCallback(
			(props) => (
				<BadgeImageInput
					{...props}
					defaultName={`${user.publisher.name}logo.jpg`}
				/>
			),
			[user]
		);

		const renderBanner = useCallback(
			(props) => (
				<BadgeImageInput
					{...props}
					defaultName={`${user.publisher.name}header.jpg`}
				/>
			),
			[user]
		);

		const onDone = useCallback(
			(key, img) => {
				if (typeof setUser === "function") {
					setUser((user) => ({
						...user,
						publisher: { ...user.publisher, [key]: img },
					}));
				}
				if (typeof onChange === "function") onChange();
			},
			[setUser, onChange]
		);

		const onProfileImageDone = useCallback(
			(img) => {
				onDone("logo_url", img);
			},
			[onDone]
		);

		const onBannerImageDone = useCallback(
			(img) => {
				onDone("banner_url", img);
			},
			[onDone]
		);

		return (
			<section className="brand-images" ref={ref} {...rest}>
				<EditImage
					url={PROFILE_IMAGE_UPLOAD_URL}
					remoteKey="logo_url"
					user={user}
					updateMethod="POST"
					editText="Brand Logo"
					selector={logoSelector}
					heading="Change Shop Logo"
					onDone={onProfileImageDone}
					customRenderer={renderLogo}
				/>

				<EditImage
					user={user}
					aspect={3}
					remoteKey="banner_url"
					url={BANNER_IMAGE_UPLOAD_URL}
					editText="Brand Banner"
					heading="Change Banner Image"
					selector={bannerSelector}
					className="branding-step-banner"
					onDone={onBannerImageDone}
					customRenderer={renderBanner}
				/>
			</section>
		);
	})
);

export default BrandImages;
