import React, { memo } from "react";
import useContent from "../../hooks/useContent";
import useIdentity from "../../hooks/useIdentity";
import AudiencesOverviewModule from "../AudiencesOverviewModule";
import "./audiences-integrations.css";
import {
	MetaIntegration,
	SnapIntegration,
	TiktokIntegration,
} from "./audiencesIntegration";

const AudiencesIntegrations = memo(() => {
	const content = useContent().platformAudiences;
	const { user } = useIdentity();

	return (
		<AudiencesOverviewModule
			heading="Integrations"
			subHeading={content.integrationsText}
		>
			<MetaIntegration />
			<SnapIntegration />
			{user.publisher.tiktok_audiences_beta && <TiktokIntegration />}
		</AudiencesOverviewModule>
	);
});

export default AudiencesIntegrations;
