import {
	FONT_COLOR,
	FONT_SIZE,
	PaddedContainer,
	Radio,
	SPACING,
	Text,
} from "@disco/disco_core";
import React, { memo, useCallback } from "react";
import {
	AUDIENCES_PROVIDERS,
	AUDIENCES_SIZES,
	TIKTOK_AUDIENCES_SIZES,
} from "../../conf";
import { PLATFORM_AUDIENCES_SELECT_REQUEST_SIZE } from "../../events";
import useTrack from "../../hooks/useTrack";
import "./audiences-sizes.css";

const AudienceSize = memo(
	({ selected, name, range, text, index, onChange }) => {
		return (
			<PaddedContainer
				vPadding={SPACING.MEDIUM}
				hPadding={SPACING.REGULAR}
				marginRight={SPACING.TINY}
				marginTop={SPACING.SMALL}
				className={`audiences-size ${
					selected ? "audiences-size-selected" : ""
				}`}
				onClick={() => onChange(index)}
			>
				<Radio selected={selected} marginRight={SPACING.REGULAR} />
				<PaddedContainer>
					<Text size={FONT_SIZE.LG_BODY} thick>
						{name}
					</Text>
					<Text
						marginTop={SPACING.MICRO}
						size={FONT_SIZE.BODY}
						color={FONT_COLOR.MID}
					>
						{range}
					</Text>
					<Text
						marginTop={SPACING.MICRO}
						size={FONT_SIZE.BODY}
						color={FONT_COLOR.LIGHT}
					>
						{text}
					</Text>
				</PaddedContainer>
			</PaddedContainer>
		);
	}
);

export const AudiencesSizes = memo(({ provider, size, setSize }) => {
	const track = useTrack();

	const handleChange = useCallback(
		(value) => {
			setSize(value);
			track(PLATFORM_AUDIENCES_SELECT_REQUEST_SIZE, {
				size: AUDIENCES_SIZES[value].name,
			});
		},
		[setSize, track]
	);

	return (
		<>
			<PaddedContainer className="audiences-sizes">
				{(provider === AUDIENCES_PROVIDERS.TIKTOK
					? TIKTOK_AUDIENCES_SIZES
					: AUDIENCES_SIZES
				).map((sizeData, index) => (
					<AudienceSize
						{...sizeData}
						index={index}
						selected={index === size}
						onChange={handleChange}
					/>
				))}
			</PaddedContainer>
		</>
	);
});

export default AudiencesSizes;
