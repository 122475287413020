import {
	Button,
	FONT_COLOR,
	FONT_SIZE,
	Loader,
	PaddedContainer,
	SPACING,
	StatusLabel,
	Text,
} from "@disco/disco_core";
import React, { memo, useEffect, useState } from "react";
import { AUDIENCES_PROVIDER_DATA } from "../../conf";
import { PLATFORM_AUDIENCES_CONNECT_INTEGRATION_ACCOUNT } from "../../events";
import useIdentity from "../../hooks/useIdentity";
import useModal from "../../hooks/useModal";
import useResource from "../../hooks/useResource";
import useTrack from "../../hooks/useTrack";
import parseError from "../../utils/parseError";
import Modal from "../modals/Modal";
import "./audiences-platform-connect.css";

const AudiencesPlatformConnectModal = memo(
	({ onClose, permissionText, permissions, provider, ...rest }) => {
		const { name, initUrl, icon } = AUDIENCES_PROVIDER_DATA[provider] || {};
		const [redir, setRedir] = useState(false);
		const [{ loading, error, data }, initConnection] = useResource(
			{
				url: initUrl,
				method: "POST",
				data: {
					provider: provider,
				},
			},
			false
		);

		const track = useTrack();

		useEffect(() => {
			if (!data?.oauth_url) return;

			track(PLATFORM_AUDIENCES_CONNECT_INTEGRATION_ACCOUNT, {
				integration: name,
			});

			setRedir(true);

			// To allow track call to go through
			setTimeout(() => {
				window.location.href = data.oauth_url;
			}, 300);
		}, [data, track, name]);

		return (
			<Modal
				className="audiences-platform-connect-modal"
				customModal
				{...rest}
				onClose={onClose}
			>
				<Text
					thick
					size={FONT_SIZE.SUB_TITLE}
					Element="section"
					className="audiences-platform-connect-modal-logo"
				>
					<img src={icon} alt="" /> {name}
				</Text>
				<Text size={FONT_SIZE.LG_BODY} marginTop={SPACING.MEDIUM}>
					{permissionText}
				</Text>
				{permissions && (
					<PaddedContainer
						marginTop={SPACING.REGULAR}
						className="audiences-platform-connect-modal-permissions"
					>
						{permissions.map((permission) => (
							<Text
								marginTop={SPACING.SMALL}
								size={FONT_SIZE.LG_BODY}
								color={FONT_COLOR.MID}
							>
								{permission}
							</Text>
						))}
					</PaddedContainer>
				)}
				{error && (
					<StatusLabel marginTop={SPACING.REGULAR}>
						{parseError(error)}
					</StatusLabel>
				)}
				<footer>
					{loading || redir ? (
						<Loader />
					) : (
						<>
							<Button rounded secondary onClick={onClose}>
								Cancel
							</Button>
							<Button
								rounded
								onClick={initConnection}
								marginLeft={SPACING.REGULAR}
							>
								Connect {name}
							</Button>
						</>
					)}
				</footer>
			</Modal>
		);
	}
);

export const AudiencesPlatformConnect = memo(({ provider, ...rest }) => {
	const {
		open: permOpen,
		handleOpen: handlePermOpen,
		handleClose: handlePermClose,
	} = useModal();

	const { user } = useIdentity();
	const { name, connectionKey } = AUDIENCES_PROVIDER_DATA[provider];
	const connected = user.publisher[connectionKey];

	return (
		<>
			<PaddedContainer className="audiences-platform-connect">
				{!connected ? (
					<Button onClick={handlePermOpen} rounded>
						Connect {name}
					</Button>
				) : (
					<Text size={FONT_SIZE.BODY} inline>
						{name}:{" "}
						<Text
							inline
							className="audiences-platform-connect-done"
						>
							Connected
						</Text>
					</Text>
				)}
			</PaddedContainer>
			<AudiencesPlatformConnectModal
				open={permOpen}
				onClose={handlePermClose}
				provider={provider}
				{...rest}
			></AudiencesPlatformConnectModal>
		</>
	);
});

export default AudiencesPlatformConnect;
