// This file is still called segment.js but the underlying code is now directly hooking into the mixpanel SDK
// Segment has been deprecated
// TODO: Update filename and corresponding imports
export const initInstance = (user) => {
	let trimmedUser = JSON.parse(JSON.stringify(user));
	delete trimmedUser.draft;
	delete trimmedUser.publisher.blacklist;
	delete trimmedUser.publisher.email_blacklist;
	delete trimmedUser.publisher.offer_blacklist;

	if (!window?.mixpanel || typeof window?.mixpanel?.identify !== "function")
		return;

	window.mixpanel.identify(user?.publisher?.remote_id);

	if (!window?.mixpanel?.people?.set !== "function") return;

	window.mixpanel.people.set({ ...(trimmedUser || {}) });
};

export const logoutSegment = () => {
	if (!window?.mixpanel || typeof window?.mixpanel?.reset !== "function")
		return;
	window.mixpanel.reset();
};
